import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { BsArrowRight } from "react-icons/bs";
function General() {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <Accordion defaultActiveKey={["0"]}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                Who is eligible to compete in WGC competitions?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                Everyone with a mobile device is eligible to compete! WGC
                competitions are available to all players within legal age
                groups in areas of the world where skill-based gameplay is
                legal. We are continuing to work in bringing more games to more
                players around the globe.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                How does WGC work? Can I win real money?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                WGC game platform that lets you compete in mobile games with
                other people around the world for WGC rewards. And you can
                exchange your rewarded WGC on our designated exchange to cash
                out.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                How much does WGC cost?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                WGC coin pricing will vary based on market rate. You may take
                advantage of low pricing in the beginning stages to take
                advantage of any potentials to make further gains in the future.
                However, this not a guarantee of a gain, as it relies on
                principles of supply and demand.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
}

export default General;
