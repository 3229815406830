import React from "react";
import { Tab, Tabs } from "react-bootstrap";
function TermsAndServies() {
  return (
    <>
      <div className="container-fluid about-banner">
        <div className="container">
          <h1 className="banner-title mb-5 font-trajan banner-title-border text-start">
            Terms of Service
          </h1>
          <div className="padding-right-25 mb-5 text-start">
            <h3 className="text-white">
              V.1 December 2022 HELLO AND WELCOME TO WORLD GAME PLATFORM
            </h3>
            <p className="banner-desc color-grey mb-5">
              We really hope you enjoy being a part of our community and
              participating in our online game platform and skill-based
              challenges. WORLD GAME PLATFORM is a games platform. We operate
              the website and manage the services available, however it is
              important to note that we publish our own games but also act as a
              reseller and agent for third-party games developers and game
              credit suppliers. Therefore we do not hold ownership or IP rights
              to some of these games nor for any game credits themselves. The
              games competitions are generally offered by third-party developer
              partners (unless specified otherwise) who have integrated their
              games with WORLD GAME PLATFORM and using other applications, tools
              and services offered by third-party developer partners who have
              integrated WORLD GAME PLATFORM into their competitions ​YOU
              ACKNOWLEDGE THAT YOUR ABILITY TO PARTICIPATE IN COMPETITIONS AND
              USE THE SERVICES OFFERED BY THIRD PARTY DEVELOPERS DOES NOT
              ESTABLISH WORLD GAME PLATFORM AS A PROVIDER OF COMPETITIONS OR THE
              SERVICES, UNLESS SPECIFIED OTHERWISE BY WORLD GAME PLATFORM. ​By
              registering for an account with WORLD GAME PLATFORM, using the
              services in any way, clicking “I Accept”, downloading any
              application integrated with WORLD GAME PLATFORM, or registering
              for or participating in any competitions or tournaments, you:
              Acknowledge that you have read these Terms of Service and all
              obligations and rules that may be included within each competition
              in which you participate in their entirety; Agree to be bound by
              the terms; and
            </p>
            <p className="banner-desc color-grey">
              Are over 18 years of age and are authorized and able to accept
              these terms. ​If you do not wish to be bound by these Terms of
              Service, do not click “I accept” and do not register with WORLD
              GAME PLATFORM and do not use the services offered on this website.
              Declining to accept these Terms of Service means you will be
              unable to participate in Competitions or set up/use your WORLD
              GAME PLATFORM account.
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="">
          <Tabs
            defaultActiveKey="General-Terms"
            // id="justify-tab-example"
            className="tabs-margin-cs container"
          >
            <Tab
              eventKey="General-Terms"
              title="General Terms"
              className="tabs-section container"
            >
              <div className="text-start">
                <div className="mb-4">
                  <h3 className="text-white mb-3">
                    Changes to the Terms of Service.
                  </h3>
                  <p className="banner-desc color-grey">
                    We may amend, change, modify or revise the Terms of Service
                    at any time, and we may post a notice on our website at of
                    any material changes, and you can see when these Terms of
                    Service were last revised by referring to the version
                    control date above. Your continued participation in
                    competitions and tournaments and/or use of our services
                    means you accept any new or modified Terms of Service. You
                    are responsible for reviewing the Terms of Service for any
                    changes, and we encourage you to check back here from time
                    to time.
                  </p>
                </div>
                <div className="mb-3">
                  <h3 className="text-white mb-3">Eligibility</h3>
                  <p className="banner-desc color-grey">
                    To be eligible to register an Account, to participate in any
                    competition or receive services, you must: be a natural
                    person who is at least 18 years of age or older, and who is
                    personally assigned to the email address submitted during
                    your account registration; have the power to enter into a
                    contract with WORLD GAME PLATFORM; be physically located in
                    a jurisdiction where participation in online competitions
                    and tournaments involving games of skill are permitted; and
                    at all times abide by these Terms of Service. If any one of
                    these requirements is not met at any time, WORLD GAME
                    PLATFORM, on behalf of our developer partners and suppliers,
                    may suspend or close your account with or without notice.
                  </p>
                </div>
                <div className="mb-3">
                  <h3 className="text-white mb-3">Registration</h3>
                  <p className="banner-desc color-grey">
                    When you create an Account, you will be asked for a
                    legitimate email address that you control and to create a
                    password. After registration, you will be given the
                    opportunity to create a username or accept a username given
                    by the Services. The password and username are needed to
                    participate in the Services. As a registered user, you can
                    update your account settings, including your email address,
                    by logging into your Account inside any WORLD GAME PLATFORM
                    enabled game and clicking “Account Settings”. Also, if you
                    forget either your password or username, you can visit the
                    Website or email us for help. Please keep your username and
                    password secret because you are responsible for all activity
                    in your Account. Although we may offer a feature that allows
                    you to “save” or “remember” your password, this feature
                    makes it possible for third parties to access your Account,
                    so please use that feature prudently because such use is at
                    your own risk. We may, in our sole discretion, reject,
                    change, suspend and/or terminate your username.
                  </p>
                </div>

                <div className="mb-3">
                  <h3 className="text-white mb-3">​​Your Account</h3>
                  <p className="banner-desc color-grey">
                    ​As the holder of your account, you are solely responsible
                    for complying with these Terms of Service, and only you are
                    entitled to all benefits accruing thereto. You may not allow
                    any other person to: access your account; access Services
                    through your account; or accept or use prizes, winnings and
                    other representative of value (including without limitation
                    digital trophies, virtual currency or virtual goods)
                    (collectively “Winnings”).
                  </p>
                  <p className="banner-desc color-grey">
                    Neither your account nor Winnings are transferable to any
                    other person or account. You must immediately notify us of
                    any unauthorised use of your account, password or
                    identification or any other breach or threatened breach of
                    our security or the security of your account in any WORLD
                    GAME PLATFORM-enabled game.
                  </p>
                </div>
                <div className="mb-3">
                  <h3 className="text-white mb-3">Personal Location Data:</h3>
                  <p className="banner-desc color-grey">
                    As a part of this service, on behalf of our developer
                    partners, we collect location information from your mobile
                    device. If you would like to opt out of having this
                    information collected, you can disable location access to
                    any game with the WORLD GAME PLATFORM through the Settings
                    menu on your mobile device.
                  </p>
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="Service"
              title="Service"
              className="tabs-section container"
            >
              <div className="text-start">
                <div className="mb-3">
                  <h3 className="text-white mb-3">​The Services.</h3>
                  <p className="banner-desc color-grey">
                    ​On behalf of our developer partners, we may, with or
                    without notice to you: modify, suspend or terminate your
                    access to the website and/or services for any reason without
                    liability; and interrupt the operation of the website,
                    and/or services as necessary to perform maintenance, error
                    correction, or other work.
                  </p>
                  <p className="banner-desc color-grey">
                    ​As agent for our developer partners, we may suspend and/or
                    close the account of any user who violates, or whom we
                    reasonably believe may be in violation of or will violate,
                    these Terms of Service, at any time without notice and
                    without liability. Also, and without limiting our other
                    rights or remedies, if we believe you have violated these
                    Terms of Service or if you have violated these Terms of
                    Service, as agent for our developer partners, we may
                    determine that your Winnings, if any, will be forfeited,
                    disgorged or recouped.
                  </p>
                </div>
                <div className="mb-3">
                  <h3 className="text-white mb-3">Third Party Sites</h3>
                  <p className="banner-desc color-grey">
                    ​You may be able to access third-party websites or services
                    via our website and/or services. We are not responsible for
                    third-party websites, services, or content available through
                    those third-party services. You are solely responsible for
                    your dealings with third-parties (including advertisers and
                    game developers). Your use of third-party websites and/or
                    services may be subject to that third-party’s terms and
                    conditions.
                  </p>
                </div>
                <div className="mb-3">
                  <h3 className="text-white mb-3">Refund Policy</h3>
                  <p className="banner-desc color-grey">
                    All purchases are final and non-refundable. Please choose
                    your item carefully prior to finalizing the transaction
                    (which includes ensuring that you have selected the correct
                    item).
                  </p>
                  <p className="banner-desc color-grey">
                    Although in most instances, purchased game credits appear
                    immediately on your game account, please note that in some
                    cases, it may take up to 60 minutes for game credits to
                    appear. Please contact us if you face any issues or have any
                    questions regarding a recent purchase.
                  </p>
                  <p className="banner-desc color-grey">
                    By purchasing from our store (either in the game app or via
                    our website) you acknowledge and agree that any applicable
                    fees and other charges for fee-based services are payable in
                    advance and not refundable in whole or in part. You are
                    fully liable for all charges to your card account, including
                    any unauthorized charges and/or any additional card
                    transaction fees imposed by your financial provider.
                  </p>
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="Compliance-with-Laws"
              title="Compliance with Laws"
              className="tabs-section container"
            >
              <div className="text-start">
                <div className="mb-3">
                  <h3 className="text-white mb-3">
                    Prohibited States/Territories/Countries
                  </h3>
                  <p className="banner-desc color-grey">
                    You acknowledge that various rules, regulations and laws
                    addressing sweepstakes, contests, competitions and
                    tournaments with entry fees and/or prizes govern your
                    participation in Competitions (“Gaming Laws”), and that
                    Gaming Laws are set up by each individual state, territory,
                    or country. Therefore, WORLD GAME PLATFORM DOES NOT permit
                    Cash Competitions to be offered to users participating in
                    Competitions in any jurisdiction in which such Competition
                    violates its Gaming Laws (“Prohibited Jurisdiction”), and if
                    you are located in any Prohibited Jurisdiction then you may
                    not participate in Cash Competitions. Together with our
                    developer partners, we reserve the right (but have no
                    obligation) to monitor the location from which you access
                    our site and services, and on behalf of our developer
                    partners, we may block access from any Prohibited
                    Jurisdiction. Each time you log in to participate in a Cash
                    Competition, you must accurately confirm the location from
                    which you are playing.
                  </p>
                  <p className="banner-desc color-grey">
                    In addition to Gaming Laws, you are also subject to all
                    government laws, rules and regulations of the state,
                    territory and/or country in which you reside and from which
                    you access and use our site and services. SERVICES AND
                    COMPETITIONS ARE VOID WHERE PROHIBITED OR RESTRICTED BY
                    APPLICABLE LAWS. Your participation in competitions and
                    tournaments is at your own risk, and you agree not to hold
                    WORLD GAME PLATFORM responsible or liable if applicable laws
                    restrict or prohibit your access or participation.
                  </p>
                </div>
                <div className="mb-3">
                  <h3 className="text-white mb-3">Legal Disclaimers</h3>
                  <p className="banner-desc color-grey">
                    WE MAKE NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR
                    IMPLIED, AS TO THE LAWFULNESS OF YOUR PARTICIPATING IN ANY
                    COMPETITION OFFERED BY WORLD GAME PLATFORM, OUR DEVELOPER
                    PARTNERS OR USE OF OUR SERVICES, NOR SHALL ANY PERSON
                    AFFILIATED, OR CLAIMING AFFILIATION, WITH US HAVE AUTHORITY
                    TO MAKE ANY SUCH REPRESENTATIONS OR WARRANTIES.
                  </p>
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="Representations-and-Warranties"
              title="Representations & Warranties"
              className="tabs-section container"
            >
              <div className="text-start">
                <div className="mb-3">
                  <h3 className="text-white mb-3">
                    Your Representations and Warranties
                  </h3>
                  <p className="banner-desc color-grey">
                    <strong>
                      You represent and warrant to WORLD GAME PLATFORM that:
                    </strong>{" "}
                    You have the right, authority, and legal capacity to agree
                    to these Terms of Service, to register for a WORLD GAME
                    PLATFORM account, and to participate in those Competitions
                    for which you register; and You will comply with these Terms
                    of Service when participating in Competitions, receiving
                    services; and All information you supply to us or our
                    developer partners is complete, accurate and current (and
                    knowingly submitting incomplete or inaccurate information,
                    or failing to update and maintain current, complete and
                    accurate information, may result, without limitation, in
                    immediate termination of your WORLD GAME PLATFORM account
                    and forfeiture of any winnings).
                  </p>
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="Your-Indemnification"
              title="Indemnification"
              className="tabs-section container"
            >
              <div className="text-start">
                <div className="mb-3">
                  <h3 className="text-white mb-3">Your Indemnification</h3>
                  <p className="banner-desc color-grey">
                    You will, at your own cost and expense, indemnify and hold
                    WORLD GAME PLATFORM and our directors, officers, employees
                    and agents harmless from and against any and all claims,
                    disputes, liabilities, judgments, settlements, actions,
                    debts or rights of action, losses of whatever kind, and all
                    costs and fees, including reasonable legal and attorneys'
                    fees, arising out of or relating to:
                  </p>
                  <p className="banner-desc color-grey">
                    <strong> Your breach of these Terms of Service;</strong>
                    Any use of your WORLD GAME PLATFORM account, the website,
                    and the services by any person including yourself; Your
                    violation of Applicable Laws; and/or Your negligence or
                    misconduct; and, if we instruct you in writing, you will, at
                    your cost and expense, defend us from any of the foregoing
                    using counsel reasonably acceptable to us.
                  </p>
                </div>
                <div className="">
                  <h5 className="text-primary">
                    WORLD GAME PLATFORM TERMS OF SERVICE
                  </h5>
                  <p className="banner-desc color-grey">
                    <a href="mailto:info@worldgamecommunity.com">
                      info@worldgamecommunity.com
                    </a>{" "}
                    <span className="banner-desc color-grey">
                      ©2022 WORLD GAME PLATFORM
                    </span>
                  </p>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default TermsAndServies;
