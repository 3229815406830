import React, { useEffect, useState } from "react";
import { BsArrowDown } from "react-icons/bs";
import { useParams } from "react-router-dom";
import CommonBanner from "../../components/CommonBanner";
import GameSliderSingle from "../../components/GameSliderSingle";
import Modal from "react-bootstrap/Modal";
import { getRequest } from "../../utility/api";
import { ColorRing } from "react-loader-spinner";
import { getAllGames } from "../../services/gameService";
// slick slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import QRCode from "react-qr-code";
function GameInside() {
  // slick slider
  const [mainSlider, setMainSlider] = useState();
  const [thumbnailSlider, setThumbnailSlider] = useState();
  // slick slider

  const [show, setShow] = useState(false);
  const [showImg, setShowImg] = useState("");
  const { gamename } = useParams();
  const [game, setGame] = useState({});
  const [loading, setLoading] = useState(false);
  const [allGames, setAllGames] = useState([]);
  const [isQrCode, setIsQrCode] = useState(false);
  const handleModalQrcodeShow = () => {
    setIsQrCode(true);
  };
  const handleModalQrcodeClose = () => {
    setIsQrCode(false);
  };
  const handleModalAndorid = (id) => {
    setShow(true);
    if (id === 1) {
      setShowImg("/assets/icons/android.png");
    }
    if (id === 2) {
      setShowImg("/assets/icons/apple.png");
    }
    if (id === 3) {
      setShowImg("/assets/icons/desktop.png");
    }
    console.log(id, "current id");
  };
  const handleModalAndoridClose = () => {
    setShow(false);
  };

  const fetchGame = async () => {
    setLoading(true);
    try {
      let { data } = await getRequest(`games/${gamename}?lang=en-US`);
      setGame(data);
      console.log(data, "data 1");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getAllGamesCall = async () => {
    try {
      var { data } = await getAllGames(10);
      if (data) {
        console.log(data, "data 2");
        setAllGames(data);
      }
    } catch (error) {}
  };

  const getDownloadLink = (game) => {
    return game?.apps?.filter(t => t.app_type_id == 3)?.[0].download_link;
  };

  useEffect(() => {
    if (gamename) {
      fetchGame();
    }
  }, [gamename]);

  useEffect(() => {
    getAllGamesCall();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ColorRing
          visible={true}
          height="100"
          width="100"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["white", "white", "white", "white", "white"]}
        />
      </div>
    );
  }

  return (
    <>
      <CommonBanner game={game} />
      <div className="buy-game-section">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div>
                <div className="mb-3 game-detail-main-img">
                  <Slider
                    asNavFor={thumbnailSlider}
                    ref={(slider1) => setMainSlider(slider1)}
                    fade={true}
                    dots={false}
                    arrows={false}
                  >
                    {game?.screenshots?.length > 0 &&
                      game?.screenshots?.map((item) => {
                        return (
                          <div>
                            <div className="" key={item.id}>
                              <img
                                src={item.url}
                                className="img-fluid"
                                alt="wgc-thumbnial"
                              ></img>
                            </div>
                          </div>
                        );
                      })}
                  </Slider>
                </div>
                <Slider
                  asNavFor={mainSlider}
                  ref={(slider2) => setThumbnailSlider(slider2)}
                  slidesToShow={4}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  dots={false}
                  arrows={false}
                  centerMode={true}
                >
                  {game?.screenshots?.length > 0 &&
                    game?.screenshots?.map((item) => {
                      return (
                        <div>
                          <div className="" key={item.id}>
                            <img
                              src={item.url}
                              className="img-fluid"
                              alt="wgc-thumbnial"
                            ></img>
                          </div>
                        </div>
                      );
                    })}
                </Slider>
              </div>
            </div>
            <div className="col-md-4">
              <h1 className="banner-title mb-4 font-trajan text-left">
                {game.title}
              </h1>
              <div className="mb-5 text-left">
                <h6 className="text-warning text-title-border p-relative">
                  {game?.category_name}
                </h6>
              </div>
              <div className="mb-3">
                <p className="banner-desc text-left">{game?.description}</p>
              </div>
              <div className="download-os-btn mb-4">
                <button onClick={() => handleModalAndorid(1)}>
                  <span>
                    <img
                      src="/assets/icons/android.png"
                      width="30"
                      alt="wgc-thumbnial"
                    ></img>
                  </span>
                  Android
                </button>
                <button
                  className="d-none"
                  onClick={() => handleModalAndorid(2)}
                >
                  <span>
                    <img
                      src="/assets/icons/apple.png"
                      width="30"
                      alt="wgc-thumbnial"
                    ></img>
                  </span>
                  Apple
                </button>
                <button
                  className="d-none"
                  onClick={() => handleModalAndorid(3)}
                >
                  <span>
                    <img
                      src="/assets/icons/desktop.png"
                      width="30"
                      className="mt-1"
                      alt="wgc-thumbnial"
                    ></img>
                  </span>
                  desktop
                </button>
              </div>
              {game?.iscomingsoon ? (
                <div className="text-start">
                  <a
                    className="btn btn-download-bg btn-download-cs my-1"
                  >
                    Coming Soon
                  </a>
                </div>
              ) : (
                <div className="text-start">
                  <a
                    href={getDownloadLink(game)}
                    className="btn btn-download-bg btn-download-cs my-1"
                    download
                  >
                    Download
                    <span className="ms-2">
                      <BsArrowDown />
                    </span>
                  </a>
                  <button
                    onClick={handleModalQrcodeShow}
                    className="btn btn-download-bg btn-download-cs my-1"
                  >
                    Send to mobile
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="buy-game-section shadow-cs">
        {/* <img 
          src="/assets/images/shadow-right-mini.png"
          className="img-fluid shadow-right-mini"
        ></img>
        <img 
          src="/assets/images/shadow-left-mini.png"
          className="img-fluid shadow-left-mini"
        ></img> */}
        <div className="container">
          <div className="row">
            <div className="col-12 mb-5">
              <div className="text-center">
                <h1 className="banner-title mb-4 font-trajan banner-title-border border-center">
                  MORE GAMES
                </h1>
              </div>
            </div>
            <div className="col-12">
              <GameSliderSingle allGames={allGames} />
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleModalAndoridClose}
        centered
        size="md"
        backdrop="static"
        keyboard={false}
        className=""
      >
        <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
        <Modal.Body className="px-5 pb-5">
          <div>
            <div className="text-center">
              <img src={showImg} width={80} alt="wgc-thumbnial"></img>
              <p className="text-primary">Android</p>
            </div>
            <p className="text-center banner-desc text-light mb-3">
              This versoin of this game is supported with Android Devices.
            </p>
            <p className="text-center banner-desc text-light">
              Please Download the android version of this game and enjoy!
            </p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isQrCode}
        onHide={handleModalQrcodeClose}
        centered
        size="md"
        backdrop="static"
        keyboard={false}
        className="qrCode-modal"
      >
        <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
        <Modal.Body className="px-5 pb-5">
          <div
            style={{
              height: "auto",
              margin: "0 auto",
              // maxWidth: 64,
              width: "100%",
            }}
          >
            <QRCode
              size={300}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              // value={game?.apps?.[0].download_link}
              value={`/barooq-wgc.netlify.app/games/${game?.publicurl}`}
              viewBox={`0 0 256 256`}
            />
          </div>
          {/* {console.log("barooq-wgc.netlify.app/games/", game?.publicurl)} */}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default GameInside;
