import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const VideoBanner = () => {
  return (
    <div className="bg-video-wrap">
      <video autoPlay muted loop playsInline>
        <source src="/assets/videos/banner-video.mp4" type="video/mp4"></source>
      </video>
      <div className="container banner-title-wrapper">
        <h1 className="banner-title banner-title_2 mb-4 font-trajan">
          World Game Community <br></br> play and earn.
        </h1>
        <p className="banner-desc video-banner-desc mb-3">
          One token. Hundreds of games. Thousands of Concurrently<br></br>
          Connected Users (CCU) in self-organizing communities.
        </p>

        <div className="video-bannner-buycoin-btn">
          <Link
            to="/buy-credits"
            className="btn btn-primary-bg btn-primary-cs mb-2"
          >
            Buy Credits
            <span className="ms-2">
              <BsArrowRight />
            </span>
          </Link>
          <div>
            <Link className="play-game-link" to="/games">
              Play Game
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoBanner;
