import React from "react";

function WgcStateCard(Props) {
  return (
    <>
      <div className="col-md-6 col-lg-4 py-2">
        <div className="wgc-card h-100 d-flex align-items-center  px-4 py-4">
          <div className="d-flex align-items-center justify-content-between flex-wrap w-100">
            <div className="d-flex align-items-center justify-content-between">
              <img
                className="img-fluid me-2"
                width="70px"
                src={Props.img}
                alt="wgc-thumbnial"
              ></img>
              <div>
                <p className="mb-0 text-start">{Props.smallTitle}</p>
                <h2 className="mb-0">{Props.valueTitle} </h2>
              </div>
            </div>
            <div>
              {/* <div style={{ width: 70, height: 70 }}>{Props.progressBar}</div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WgcStateCard;
