import React, { useState, useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { Link } from "react-router-dom";
import { getAllGames } from "../../services/gameService";
import { ColorRing } from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
export default function Games() {
  const [allGames, setAllGames] = useState([]);
  const [isloadding, setIsloading] = useState(false);
  const [searchGameName, setSearchGameName] = useState("");

  const getAllGamesCall = async () => {
    setIsloading(true);
    try {
      var { data } = await getAllGames(100);
      if (data) {
        setAllGames(data);

        setIsloading(false);
      }
    } catch (error) {
    } finally {
      setIsloading(false);
    }
  };

  // filterGame
  const filterGame = () => {
    let filterGameData = allGames.filter((items) =>
      items.title.toLowerCase().includes(searchGameName.toLowerCase())
    );
    return filterGameData;
  };

  useEffect(() => {
    getAllGamesCall();
  }, []);

  return (
    <>
      <div className="games-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mb-3">
                <div className="form-group p-relative">
                  <input
                    className="form-control cs-search-input ms-auto text-white"
                    placeholder="Search"
                    value={searchGameName}
                    onChange={(e) => setSearchGameName(e.target.value)}
                  />
                  <span className="search-icon-position">
                    <BsSearch />
                  </span>
                </div>
              </div>
              <div className="text-center">
                <h1 className="banner-title mb-4 font-trajan banner-title-border border-center">
                  WGC GAMES
                </h1>
                <p className="banner-desc color-grey mb-5">
                  World of Games to make you Learn and Earn
                </p>
              </div>
            </div>
            {isloadding && (
              <ColorRing
                visible={true}
                height="100"
                width="100"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["white", "white", "white", "white", "white"]}
              />
            )}

            {filterGame().map((items) => {
              return (
                <div className="col-md-6 col-lg-6 col-xl-4 p-3" key={items.id}>
                  <div>
                    <Link
                      to={`/games/${items.publicurl}`}
                      className="game-img-hover"
                    >
                      <img
                        src={items.thumbnail}
                        className="img-fluid w-100"
                        alt="wgc-thumbnal"
                      ></img>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
