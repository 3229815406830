import React from "react";

function TalentWanted(Props) {
  return (
    <>
      <div className="col-md-6 col-lg-4 py-4">
        <div className="border border-warning h-100 px-5 py-5 hover-primary">
          <div className="text-center">
            <p className="text-warning mb-0">{Props.content}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default TalentWanted;
