import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { BsArrowRight } from "react-icons/bs";
function Support() {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <Accordion defaultActiveKey={["0"]}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                What do I do if my game crashes?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                <p>
                  Before releasing each game to our platform, we have undergone
                  a serious round of internal test to make sure the quality of
                  our games. However, as everyone can expect in this digital
                  world, bugs and suggestions are always needed to further
                  improve the user experience of our games. So, your
                  contributions and reports are very valuable to us.
                </p>
                <p>
                  You can report crashed games with descriptions (your username,
                  other players name, play time and details of the crashes), if
                  you can provide with screenshots or even videos to our Support
                  email, that will help us more.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                How can I contact you?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                You can always reach out to us by our support email, or our live
                chat system (when available)
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />I forgot both of my
                password and the username. What should I do?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                <p>
                  You must remember either your username, or the email you used
                  to register your account to retrieve your account.
                </p>
                <p>
                  Please note, we don't keep a record of your password. If you
                  lost all information, we cannot help you.
                </p>
                <p>
                  In case you remember your email address, you can request
                  password reset.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
}

export default Support;
