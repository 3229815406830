import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { BsArrowRight } from "react-icons/bs";
function Account() {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <Accordion defaultActiveKey={["0"]}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                How do I create a WGC account?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                It's simple, you just need register an account in our game
                platform with a username a valid email. Please be mindful that
                all our games are free to play. However, if you want to fully
                enjoy our WGC games, and want to win and cash out your rewards,
                you must pass a KYC process with your real ID.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                How can I change my account information?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                You can simply log in your account and check the profile.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                How can I change my username or profile picture?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                You cannot change your username, but you can change your profile
                picture at the Profile section.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                How can I change my password?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                You can change your password in the Profile section in both our
                website and in some of our games.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                Can I use my WGC account for multiple games?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                Absolutely, playing all games with a single login is one of our
                unique and proud features. We have invested heavily on the
                technology to ensure our user have a simplistic experience
                whilst ensuring it is fully secured using blockchain technology.
                We are a gaming company, with a single purpose to ensure our
                users have fun whilst our advanced technology runs in the
                background.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                Why can’t I play WGC game? What are the reasons?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                <p>
                  You must have enough WGC credits to play unlocked WGC enabled
                  features, starting as low as 0.1 WGC. Otherwise, you can also
                  enjoy our free games playing using the free hearts, energies,
                  and lives to play solo games and non-WGC mode games. Of
                  course, you play only for fun. However, to win WGC, you must
                  compete in WGC enabled matches.
                </p>
                <p>
                  For high entrance fee matches you will need to complete KYC,
                  otherwise there will be an entrance fee limit of 2 USD. This
                  is to prevent bad actors from spoiling our game. Feel free to
                  complete your KYC to unlock your highest potentials!
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                Why do I need to go through KYC?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                KYC is used as a preventative measure against bad actors and
                machine learning enabled AI computers. Our multi-tiered security
                networks will trigger alarms on strange algorithms and identify
                patterns to counter spoofing and various hack techniques. We do
                not sell or use KYC documents. Your information is safe with us.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                What benefit can I have after completing KYC?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                <p>
                  Once you complete the KYC process, you can freely unlock all
                  features and enjoy games to your heart's delight! You can
                  participate various gaming modes and events including PVP,
                  battle royale, weekly tournament and more!
                </p>
                <p>
                  You will also enable advanced features in the game such as
                  receiving, sending, selling and transferring your WGC to other
                  accounts.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
}

export default Account;
