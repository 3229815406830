import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Form from "react-bootstrap/Form";
import { FaUpload } from "react-icons/fa";
import { RiBodyScanFill } from "react-icons/ri";
import { MdReplay } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { authUserAction } from "../../../redux/slices/AuthSlice";
import {
  getUserInfo,
  KYCVerfiy,
  verifyEmail,
  verifyPhone,
} from "../apiCall/profile";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Jsondata from "../utilis/country.json";

const steps = [
  //   "Contact Info",
  //   "Verification Code",
  //   "Personal Info",
  //   "Idenity Ifo",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
];

// error form style

const errorStyle = {
  color: "red",
  fontSize: "14px!important",
};

function KycVerification() {
  const phoneInputRef = React.useRef("");
  const { authData } = useSelector((state) => state.auth);
  const [phoneInput, setPhoneInput] = React.useState("");
  const [phoneInputError, setPhoneInputError] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm();

  // watch form values
  const { step1Email, file1, file2 } = watch();

  // const isStepOptional = (step) => {
  //   return step === 1;
  // };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const ProceedNext = () => {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const callverifyEmail = async (url, text, isResendEmail) => {
    setLoading(true);
    verifyEmail(url, text)
      .then((response) => {
        fetchUserProfile();
        if (isResendEmail) {
          toast.success("email send successfully");
          return;
        } else {
          ProceedNext();
        }
      })
      .catch((error) => {
        if (error.response.data.Message) {
          toast.error(error.response.data.Message);
        } else {
          toast.error("server error try again");
        }
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const callverifyPhone = async (url, text, isResendPhone) => {
    setLoading(true);
    verifyPhone(url, text)
      .then((response) => {
        fetchUserProfile();
        if (isResendPhone) {
          toast.success("Phone code send successfully");
          return;
        } else {
          ProceedNext();
        }
      })
      .catch((error) => {
        if (error.response.data.Message) {
          toast.error(error.response.data.Message);
        } else {
          toast.error("server error try again");
        }
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const verifyKYC = async (formData) => {
    setLoading(true);
    KYCVerfiy(formData)
      .then((response) => {
        console.log(response);
        ProceedNext();
      })
      .catch((error) => {
        if (error.response.data.Message) {
          toast.error(error.response.data.Message);
        } else {
          toast.error("server error try again");
        }
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNext = (data) => {
    console.log(data, "check kyc data");
    if (activeStep === 0) {
      ProceedNext();
    } else if (activeStep === 1) {
      callverifyEmail("/profile/email", data.step1Email);
    } else if (activeStep === 2) {
      callverifyEmail("/profile/verify/email", data.emailcode);
    } else if (activeStep === 3) {
      if (!phoneInput) {
        setPhoneInputError("Phone is required");
        return;
      }
      callverifyPhone("/profile/phone", phoneInput);
    } else if (activeStep === 4) {
      callverifyPhone("/profile/verify/phone", data.phonecode);
    } else if (activeStep === 5) {
      ProceedNext();
    } else {
      console.log(data, "check kyc data");
      const formData = new FormData();
      formData.append("id_number", data.id_number);
      formData.append("first_name", data.first_name);
      formData.append("last_name", data.last_name);
      formData.append("middle_name", data.middle_name);
      formData.append("country_code", data.country_code);
      formData.append("id_type_2", data.id_type_2);
      formData.append("expiry_date", data.expiry_date);
      formData.append("issue_date", data.issue_date);
      formData.append("id_type", Number(data.id_type));
      if (data.file1.length > 0) {
        formData.append("file", data.file1[0]);
      }
      if (data.file2.length > 0) {
        formData.append("file", data.file2[0]);
      }

      verifyKYC(formData);
    }
  };

  const handleBack = () => {
    // skip the email verified back steps
    if (authData.email_verified && activeStep === 3) {
      setActiveStep(0);
    } else if (
      authData.email_verified &&
      authData.phone_verified &&
      activeStep === 5
    ) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  // const handleSkip = () => {
  //   if (!isStepOptional(activeStep)) {
  //     // You probably want to guard against something like this,
  //     // it should never occur unless someone's actively trying to break something.
  //     throw new Error("You can't skip a step that isn't optional.");
  //   }

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped((prevSkipped) => {
  //     const newSkipped = new Set(prevSkipped.values());
  //     newSkipped.add(activeStep);
  //     return newSkipped;
  //   });
  // };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const fetchUserProfile = async () => {
    getUserInfo(localStorage.getItem("sgc-token"))
      .then((res) => {
        localStorage.setItem("userdata-sgc", JSON.stringify(res.data));
        dispatch(authUserAction(res.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    if (localStorage.getItem("sgc-token")) {
      fetchUserProfile();
    }
  }, []);

  React.useEffect(() => {
    // if email is verified and phone is verified and first form is  filled by user too
    if (
      authData.email_verified &&
      authData.phone_verified &&
      activeStep === 1
    ) {
      setActiveStep(5);
    }
    // if email is verified and phone is verified but first form is not filled yet
    else if (
      authData.email_verified &&
      authData.phone_verified &&
      activeStep === 0
    ) {
      setActiveStep(0);
    }
    // if email is verified  and first form is  filled by user too
    else if (authData.email_verified && activeStep === 1) {
      setActiveStep(3);
      // if email is verified  but first form is not filled yet
    } else if (authData.email_verified && activeStep === 0) {
      setActiveStep(0);
    }
  }, [authData, activeStep]);

  React.useEffect(() => {
    if (phoneInput) {
      phoneInputRef.current = phoneInput;
    }
  }, [phoneInput]);

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          //   if (isStepOptional(index)) {
          //     labelProps.optional = (
          //       <Typography variant="caption">Optional</Typography>
          //     );
          //   }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <div className="py-5">
            <h2 className="banner-title font-trajan coming-soon-title mb-3 text-center text-light-blue-cs">
              Thank you
            </h2>
            <h5 className="text-secoundar-cs text-center">
              Your KYC is being reviewed.
            </h5>
          </div>
          {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box> */}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
          {activeStep + 1 === 2 && (
            <>
              <div className="py-5">
                <div className="form-group">
                  <label className="form-label wizard-input-label text-secoundar-cs">
                    Please enter your Email
                  </label>
                  <input
                    type="text"
                    className="form-control rounded-0 form-control-lg"
                    placeholder="Enter Email"
                    {...register("step1Email", {
                      required: "Email is required",
                    })}
                  />
                  {errors?.step1Email?.message && (
                    <div style={errorStyle}>{errors.step1Email.message}</div>
                  )}
                </div>
              </div>
            </>
          )}
          {activeStep + 1 === 3 && (
            <>
              <div className="py-5">
                <div className="form-group">
                  <label className="form-label wizard-input-label text-secoundar-cs">
                    Enter confirmation code that has been sent to your Email.
                  </label>
                  <input
                    type="text"
                    className="form-control rounded-0 form-control-lg"
                    placeholder="000-000"
                    {...register("emailcode", {
                      required: "Code is required",
                    })}
                  />
                  {errors?.emailcode?.message && (
                    <div style={errorStyle}>{errors.emailcode.message}</div>
                  )}
                  <div className="mt-1">
                    <small tag="h4" className="text-secoundar-cs">
                      Don't receive the code?{" "}
                      <span
                        className="text-light-blue-cs resend-code"
                        onClick={() =>
                          callverifyEmail("/profile/email", step1Email, true)
                        }
                      >
                        {loading ? (
                          "Loading..."
                        ) : (
                          <>
                            "Resend" <MdReplay size="30" className="ms-1" />
                          </>
                        )}
                      </span>
                    </small>
                  </div>
                </div>
              </div>
            </>
          )}
          {activeStep + 1 === 4 && (
            <>
              <div className="py-5">
                <div className="form-group">
                  <label className="form-label wizard-input-label text-secoundar-cs">
                    Please enter your phone number
                  </label>
                  <PhoneInput
                    country={"us"}
                    value={phoneInput}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    onChange={(phone) => {
                      setPhoneInput(phone);
                      setPhoneInputError("");
                    }}
                  />
                  {phoneInputError && (
                    <div style={errorStyle}>{phoneInputError}</div>
                  )}
                </div>
              </div>
            </>
          )}
          {activeStep + 1 === 5 && (
            <>
              <div className="py-5">
                <div className="form-group">
                  <label className="form-label wizard-input-label text-secoundar-cs">
                    Enter confirmation code that has been sent to your Phone
                    Number.
                  </label>
                  <input
                    type="text"
                    className="form-control rounded-0 form-control-lg"
                    placeholder="000000"
                    {...register("phonecode", {
                      required: "Code is required",
                    })}
                  ></input>
                  {errors?.phonecode?.message && (
                    <div style={errorStyle}>{errors.phonecode.message}</div>
                  )}
                  <div className="mt-1">
                    <small tag="h4" className="text-secoundar-cs">
                      Don't receive the code?{" "}
                      <span
                        className="resend-code resend-code"
                        onClick={() =>
                          callverifyPhone(
                            "/profile/phone",
                            phoneInputRef.current,
                            true
                          )
                        }
                      >
                        {loading ? (
                          "Loading..."
                        ) : (
                          <>
                            "Resend" <MdReplay size="30" className="ms-1" />
                          </>
                        )}
                      </span>
                    </small>
                  </div>
                </div>
              </div>
            </>
          )}
          {activeStep + 1 === 1 && (
            <>
              <div className="py-5">
                <div className="form-group mb-3">
                  <input
                    type="text"
                    className="form-control rounded-0 form-control-lg"
                    placeholder="First Name"
                    {...register("first_name", {
                      required: "First name is required",
                    })}
                  ></input>
                  {errors?.first_name?.message && (
                    <div style={errorStyle}>{errors.first_name.message}</div>
                  )}
                </div>
                <div className="form-group mb-3">
                  <input
                    type="text"
                    className="form-control rounded-0 form-control-lg"
                    placeholder="Last Name"
                    {...register("last_name", {
                      required: "Last name is required",
                    })}
                  ></input>
                  {errors?.last_name?.message && (
                    <div style={errorStyle}>{errors.last_name.message}</div>
                  )}
                </div>
                <div className="form-group mb-3">
                  <input
                    type="text"
                    className="form-control rounded-0 form-control-lg"
                    placeholder="Middle Name"
                    {...register("middle_name")}
                  ></input>
                </div>
                <div className="form-group mb-3">
                  <label className="text-secoundar-cs">Country:</label>
                  <select
                    aria-label="Default select example"
                    className="form-select rounded-0 form-control-lg"
                    {...register("country_code", {
                      required: "Country is required",
                    })}
                  >
                    {Jsondata.country.map((item, i) => {
                      return (
                        <option key={item.code} value={item.code}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>

                  {errors?.country_code?.message && (
                    <div style={errorStyle}>{errors.country_code.message}</div>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label className="text-secoundar-cs">ID Type:</label>
                  <select
                    aria-label="Default select example"
                    className="form-select rounded-0 form-control-lg"
                    {...register("id_type_2", {
                      required: "ID type is required",
                    })}
                    placeholder="ID Type"
                  >
                    <option key="1" value="" disabled>
                      ID Card
                    </option>
                    <option key="2" value="passport">
                      Passport
                    </option>
                    <option key="3" value="Driving License">
                      Driving License
                    </option>
                  </select>

                  {errors?.id_type_2?.message && (
                    <div style={errorStyle}>{errors.id_type_2.message}</div>
                  )}
                </div>
                <div className="form-group mb-3">
                  <input
                    type="text"
                    className="form-control rounded-0 form-control-lg"
                    placeholder="ID Number"
                    {...register("id_number", {
                      required: "ID Number is required",
                    })}
                  ></input>
                  {errors?.id_number?.message && (
                    <div style={errorStyle}>{errors.id_number.message}</div>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label className="text-secoundar-cs">ID Issue Date:</label>
                  <input
                    type="date"
                    className="form-control rounded-0 form-control-lg"
                    placeholder="Issue  date"
                    {...register("issue_date", {
                      required: "Issue date is required",
                    })}
                  ></input>
                  {errors?.issue_date?.message && (
                    <div style={errorStyle}>{errors.issue_date.message}</div>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label className="text-secoundar-cs">ID Expire Date:</label>
                  <input
                    type="date"
                    className="form-control rounded-0 form-control-lg"
                    placeholder="Expire date"
                    {...register("expiry_date", {
                      required: "Expire date is required",
                    })}
                  ></input>
                  {errors?.expiry_date?.message && (
                    <div style={errorStyle}>{errors.expiry_date.message}</div>
                  )}
                </div>
              </div>
            </>
          )}
          {activeStep + 1 === 6 && (
            <>
              <div className="py-5">
                <p className="banner-desc ">
                  In order to completed you registration, please upload a copy
                  of you identity with A clear selfie photo to proof the
                  document holder
                </p>
                <div>
                  <div className="form-group mb-5">
                    <label className="form-label wizard-input-label text-secoundar-cs mb-2">
                      Choose your identity type :
                    </label>
                    <div key="inline-radio" className="mb-3 text-white">
                      <Form.Check
                        inline
                        label="ID Card"
                        name="id_type"
                        type="radio"
                        id="inline-radio-1"
                        className="text-secoundar-cs"
                        value="0"
                        {...register("id_type", {
                          required: "This field is required",
                        })}
                      />
                      <Form.Check
                        inline
                        label="Passport"
                        name="id_type"
                        type="radio"
                        id="inline-radio-2"
                        className="text-secoundar-cs"
                        value="1"
                        {...register("id_type", {
                          required: "This field is required",
                        })}
                      />
                      <Form.Check
                        inline
                        name="id_type"
                        label="Driving License"
                        type="radio"
                        id="inline-radio-3"
                        className="text-secoundar-cs"
                        value="2"
                        {...register("id_type", {
                          required: "This field is required",
                        })}
                      />
                      {errors?.id_type?.message && (
                        <div style={errorStyle}>{errors.id_type.message}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 py-2">
                      <div class="upload_proof identity_clolum">
                        {file1 !== undefined && file1.length > 0 ? (
                          <div style={{ position: "relative", width: "100%" }}>
                            <img
                              src={URL.createObjectURL(file1?.[0])}
                              style={{
                                height: "13rem",
                                width: "100%",
                                objectFit: "cover",
                              }}
                              alt="wgc"
                            />

                            <svg
                              onClick={() =>
                                reset((formValue) => ({
                                  ...formValue,
                                  file1: undefined,
                                }))
                              }
                              title="delete"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              style={{
                                top: "-0.6rem",
                                right: "-0.6rem",
                                position: "absolute",
                                background: "white",
                                borderRadius: "50%",
                                padding: "0.1rem",
                                cursor: "pointer",
                              }}
                            >
                              <line x1="18" y1="6" x2="6" y2="18"></line>
                              <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                          </div>
                        ) : (
                          <>
                            <input
                              type="file"
                              name="proof"
                              accept="image/*"
                              {...register("file1")}
                            />
                            <div class="upload_icon">
                              <span>
                                <FaUpload
                                  size="70px"
                                  className="text-light-blue-cs"
                                />
                              </span>
                            </div>
                            <div class="upload_detail">
                              <h4 className="text-secoundar-cs">
                                Upload Proof identity
                              </h4>
                              <p className="text-secoundar-cs">
                                Click here and select the document from your
                                device
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-12 py-2">
                      <div class="upload_proof identity_clolum">
                        {file2 !== undefined && file2.length > 0 ? (
                          <div style={{ position: "relative", width: "100%" }}>
                            <img
                              src={URL.createObjectURL(file2?.[0])}
                              style={{
                                height: "13rem",
                                width: "100%",
                                objectFit: "cover",
                              }}
                              alt="wgc"
                            />

                            <svg
                              onClick={() =>
                                reset((formValue) => ({
                                  ...formValue,
                                  file2: undefined,
                                }))
                              }
                              title="delete"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              style={{
                                top: "-0.6rem",
                                right: "-0.6rem",
                                position: "absolute",
                                background: "white",
                                borderRadius: "50%",
                                padding: "0.1rem",
                                cursor: "pointer",
                              }}
                            >
                              <line x1="18" y1="6" x2="6" y2="18"></line>
                              <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                          </div>
                        ) : (
                          <>
                            <input
                              type="file"
                              name="proof"
                              accept="image/*"
                              {...register("file2")}
                            />
                            <div class="upload_icon">
                              <span>
                                {/* <ImUserCheck
                                  size="70px"
                                  className="text-light-blue-cs"
                                /> */}
                                <RiBodyScanFill
                                  size="70px"
                                  className="text-light-blue-cs"
                                />
                              </span>
                            </div>
                            <div class="upload_detail">
                              <h4 className="text-secoundar-cs">
                                Take selfie with identity
                              </h4>
                              <p className="text-secoundar-cs">
                                Please note screenshots, mobile phone bills and
                                insurance are NOT accepted for verification.
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {activeStep + 1 === 7 && (
            <div className="py-5">
              <p className="banner-desc ">
                Complete the process by clicking register
              </p>
            </div>
          )}
          <Box
            className="kyc-botton-setting"
            sx={{ display: "flex", flexDirection: "column", pt: 2 }}
          >
            <button
              onClick={handleSubmit(handleNext)}
              // variant="outlined"
              // color="primary"
              className="btn btn-dark rounded-0 btn-lg mb-3"
              // endIcon={<FaArrowRight />}
              type="submit"
              disabled={loading}
              // className="btn btn-outline-warning rounded-0 w-100 btn-lg"
            >
              {loading
                ? "loading..."
                : activeStep === steps.length - 1
                ? "Register"
                : "Next"}
            </button>

            <button
              // variant="outlined"
              disabled={
                activeStep === 0 ||
                (authData.email_verified && activeStep === 2) ||
                (authData.phone_verified && activeStep === 4) ||
                loading
              }
              onClick={handleBack}
              sx={{ mr: 1 }}
              // color="error"
              className="btn btn-dark rounded-0 btn-lg"
              // startIcon={<FaArrowLeft />}
            >
              Back
            </button>

            <Box sx={{ flex: "1 1 auto" }} />
            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
export default KycVerification;
