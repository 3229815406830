import { axiosInstance } from "../../../utility/interceptor";

// user basic info
export const getUserInfo = async (token) => {
  return axiosInstance.get("/profile/basicinfo", {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const verifyEmail = async (url, text) => {
  return axiosInstance.post(url, text, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("sgc-token")}`,
      "content-type": "application/json",
    },
  });
};

export const verifyPhone = async (url, text) => {
  return axiosInstance.post(url, text, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("sgc-token")}`,
      "content-type": "application/json",
    },
  });
};

export const KYCVerfiy = async (formData) => {
  return axiosInstance.post("/profile/kyc", formData, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("sgc-token")}`,
    },
  });
};

export const withDrawCoins = (data) => {
  return axiosInstance.post("/profile/withdrawal", data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("sgc-token")}`,
      "content-type": "application/json",
    },
  });
};
