export const ApiBaseUrl = "https://api.worldgamecommunity.com"; // Live API URL
//export const ApiBaseUrl = "https://stagingapi.mmocircles.com"; // Live API URL
//export const ApiBaseUrl = "https://localhost:7009";

export const apiUrls = {
  getAllGames: "/games",
  // auth: {
  //   login: "Auth/authenticate",
  //   validateTwoFACode: "Auth/ValidateTwoFACode",
  //   resendTwoFACode: "Auth/ResendTwoFACode",
  //   validateResetPasswordLink: "Auth/ValidateResetPasswordLink",
  //   resetPassword: "Auth/ResetPassword",
  //   forgotPassword: "Auth/ForgotPassword",

  // },
};
