import React from "react";
import { Link } from "react-router-dom";

function JobOppertunityCard(Props) {
  return (
    <div className="col-md-6 col-lg-4 py-4">
      <div className="career-card h-100  p-4">
        <div className="mb-3">
          <h6 className="card-title mb-0">Job Opening</h6>
        </div>
        <div className="mb-3">
          <h2 className="job-title font-trajan mb-0">game developer</h2>
        </div>

        <div className="mb-4">
          <p className="text-white job-type mb-1">
            Worldwide <small>(Remotely)</small>
          </p>
          <p className="text-white mb-0">via WGC Career Portal</p>
        </div>
        <div className="">
          <Link to="#">Apply Now</Link>
        </div>
      </div>
    </div>
  );
}

export default JobOppertunityCard;
