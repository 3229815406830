import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { BsArrowRight } from "react-icons/bs";
function GamesAndMatching() {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <Accordion defaultActiveKey={["0"]}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                How does WGC player match work?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                Users can enter a tournament pool by paying an entrance fee.
                Winners will be allocated majority of the prize pool, minus
                transaction costs.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                How does WGC weekly tournament work?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                WGC weekly tournament is one of the featured events on our
                platform. Players are encouraged to participate in our weekly
                hosted tournament by putting down a small entry fee. Top 20
                winners will share the prize pool which is combination of the
                entry fees from all participants. The higher your rank is for
                the week, the higher your prize percentage will be, and the
                earlier you participate, the higher rank you will get when in
                case of a draw. The Winners are based on net WGC winning
                accumulated within the tournament period from the time you enter
                the tournament. Feel free to enter and make WGC game count, with
                a chance to win double. Don't forget to participate at the
                beginning of each week! You can find more detailed rules at the
                WGC weekly tournament section in our games.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                What is Battle Log?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                The Battle log records all the wins and losses from competitive
                matches. If you play passive PVP in our solo WGC games, your
                scores will be recorded in the battle log and matched against
                the next player. If you participate in a passive PVP game, you
                can refer to the battle log to see whether you won or lost
                against the next player.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                What is Passive PVP and Real-time PVP?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                <p>
                  Real-time PVP, is two real players play against each other or
                  others at the same time. The result will also show immediately
                  when game is over.
                </p>
                <p>
                  Passive PVP, is a mode which is enabled when the Real-time PVP
                  is not available, and there's no other players for you to
                  compete against at the time. Your game result will be
                  automatically submitted to our system in the backstage, and
                  your score will be used to compete against the next player in
                  line who also doesn't have a real-time opponent.
                </p>
                <p>
                  Depending on the nature of each game, some games provide
                  Real-time PVP mode, or Passive PVP mode, and some will provide
                  both modes of play.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                What happens if I don't have a real time player?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                <p>
                  Depending on the nature of each game. Some game will allow you
                  to continue playing until a real player joining your current
                  game, and then you will start competing against each other or
                  you will finish the game as a free game for fun or practice if
                  no one joins during your game time. No WGC will be deducted
                  from your account if it's a practice game.
                </p>
                <p>
                  Some games will enter Passive PVP mode if no real time player
                  is found, your result will be used to compete against the next
                  player in line. In this case, every game you play counts, so
                  always try your best.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                How do I know I have a successful match from Passive PVP game?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                <p>
                  You can check the Battle Log menu usually in the lobby of each
                  game, you can find all your finished and completed games for
                  the Passive PVP games played.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                Where can I find my game history?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                <p>There are two ways to check your WGC game history.</p>
                <p>
                  You can either check all your history from our website or
                  check the WGC History section within the game.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                Where can I view my WGC balance?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                <p>
                  You can view your WGC balance from our game platform, your
                  profile section, or profile section in our games.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
}

export default GamesAndMatching;
