import React from "react";

import QATabs from "./components/Q&ATabs";
function QuestionAnswer() {
  return (
    <>
      <div className="container-fluid ">
        <div className="container qa-banner">
          <h1 className="banner-title  font-trajan banner-title-border text-start">
            Q&A
          </h1>
        </div>
      </div>
      <div className="">
        <QATabs />
      </div>
    </>
  );
}

export default QuestionAnswer;
