import React from "react";
import { FaRegTimesCircle } from "react-icons/fa";
const PaymentDecline = () => {
  return (
    <div className="container-fluid pt-5">
      <div className="container">
        <div className="text-center">
          <span>
            <FaRegTimesCircle className="icon-style-msg mb-3" size="10rem" />
          </span>
          <h1 className="banner-title font-trajan coming-soon-title mb-5">
            Sorry Please Try Again
          </h1>
        </div>{" "}
      </div>
    </div>
  );
};

export default PaymentDecline;
