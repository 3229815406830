import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
const PaymentSuccess = () => {
  return (
    <>
      <div className="container-fluid pt-5">
        <div className="container">
          <div className="text-center">
            <span>
              <FaRegCheckCircle className="icon-style-msg mb-3" size="10rem" />
            </span>
            <h1 className="banner-title font-trajan coming-soon-title mb-5">
              Thank you for your purchase! Your WGC will be deposited to your
              account shortly. Click{" "}
              <Link to="/profile" style={{ textDecoration: "none" }}>
                here
              </Link>{" "}
              to go back to your account.
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccess;
