import React from "react";

function CareerCards(Props) {
  return (
    <>
      <div className="col-md-6 col-lg-4 py-4">
        <div className="career-card h-100">
          <div className="mb-3">
            <img
              src={Props.cardImg}
              className="img-fluid"
              alt="wgc-thumbnial"
            ></img>
          </div>
          <div className="text-center px-4 pb-4">
            <p className="text-white fs-14 mb-0">{Props.content}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default CareerCards;
