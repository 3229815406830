import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authData: "",
  isLogin: false,
  isShowModal: false,
  isShowContactModal: false,
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authUserAction: (state, action) => {
      return {
        ...state,
        authData: action.payload,
        isLogin: true,
        isShowModal: false,
      };
    },
    toggleModal: (state, action) => {
      return {
        ...state,
        isShowModal: action.payload,
      };
    },
    toggleContactModal: (state, action) => {
      return {
        ...state,
        isShowContactModal: action.payload,
      };
    },
    logoutAction: (state) => {
      return {
        ...state,
        isLogin: false,
        isShowModal: false,
        authData: "",
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { authUserAction, logoutAction, toggleModal, toggleContactModal } =
  AuthSlice.actions;

export default AuthSlice.reducer;
