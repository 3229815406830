import axios from "axios";
import { ApiBaseUrl } from "../constants/endpoints";
import { axiosInstance } from "../utility/interceptor";

axios.defaults.baseURL = ApiBaseUrl;

export function stripe_session(val, url) {
  return axiosInstance.post(ApiBaseUrl + url, val, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("sgc-token")}`,
    },
  });
}
