import React, { useState } from "react";
import { GoPrimitiveDot } from "react-icons/go";

import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
const CoinsProgress = () => {
  const [mainImg, setMainImg] = useState("/assets/images/coins-pack.png");

  const handleProgressStep = (e, id) => {
    let borerClass = document.querySelectorAll(".abc");
    console.log(e, borerClass);
    borerClass.forEach((i) => {
      i.classList.remove("border-class-dot");
    });

    // let NonActiveClassToggle = document.querySelectorAll(".non-active-icon");
    // NonActiveClassToggle.forEach((i) => {
    //   i.classList.remove("d-none");
    // });
    let activeClassToggle = document.querySelectorAll(".active-icon");
    activeClassToggle.forEach((i) => {
      i.classList.add("d-none");
    });
    debugger;
    if (id === 1) {
      document.getElementById("1").classList.add("border-class-dot");
      document.getElementById("icon-setActive-1").classList.add("d-none");
      document.getElementById("icon-setActive-2").classList.remove("d-none");
      document.getElementById("icon-setActive-3").classList.remove("d-none");
      document.getElementById("icon-setActive-4").classList.remove("d-none");
      document.getElementById("icon-setActive-5").classList.remove("d-none");
      document.getElementById("icon-setActive-6").classList.remove("d-none");
      document.getElementById("icon-set-1").classList.remove("d-none");
      setMainImg("/assets/icons/coin_1.png");
    }
    if (id === 2) {
      document.getElementById("2").classList.add("border-class-dot");
      document.getElementById("icon-setActive-1").classList.remove("d-none");
      document.getElementById("icon-setActive-2").classList.add("d-none");
      document.getElementById("icon-setActive-3").classList.remove("d-none");
      document.getElementById("icon-setActive-4").classList.remove("d-none");
      document.getElementById("icon-setActive-5").classList.remove("d-none");
      document.getElementById("icon-setActive-6").classList.remove("d-none");
      document.getElementById("icon-set-2").classList.remove("d-none");
      setMainImg("/assets/icons/coin_2.png");
    }
    if (id === 3) {
      document.getElementById("3").classList.add("border-class-dot");
      document.getElementById("icon-setActive-1").classList.remove("d-none");
      document.getElementById("icon-setActive-2").classList.remove("d-none");
      document.getElementById("icon-setActive-3").classList.add("d-none");
      document.getElementById("icon-setActive-4").classList.remove("d-none");
      document.getElementById("icon-setActive-5").classList.remove("d-none");
      document.getElementById("icon-setActive-6").classList.remove("d-none");
      document.getElementById("icon-set-3").classList.remove("d-none");
      setMainImg("/assets/icons/coin_3.png");
    }
    if (id === 4) {
      document.getElementById("4").classList.add("border-class-dot");
      document.getElementById("icon-setActive-1").classList.remove("d-none");
      document.getElementById("icon-setActive-2").classList.remove("d-none");
      document.getElementById("icon-setActive-3").classList.remove("d-none");
      document.getElementById("icon-setActive-4").classList.add("d-none");
      document.getElementById("icon-setActive-5").classList.remove("d-none");
      document.getElementById("icon-setActive-6").classList.remove("d-none");
      document.getElementById("icon-set-4").classList.remove("d-none");
      setMainImg("/assets/icons/coin_4.png");
    }
    if (id === 5) {
      document.getElementById("5").classList.add("border-class-dot");
      document.getElementById("icon-setActive-1").classList.remove("d-none");
      document.getElementById("icon-setActive-2").classList.remove("d-none");
      document.getElementById("icon-setActive-3").classList.remove("d-none");
      document.getElementById("icon-setActive-4").classList.remove("d-none");
      document.getElementById("icon-setActive-5").classList.add("d-none");
      document.getElementById("icon-setActive-6").classList.remove("d-none");
      document.getElementById("icon-set-5").classList.remove("d-none");
      setMainImg("/assets/icons/coin_5.png");
    }
    if (id === 6) {
      document.getElementById("6").classList.add("border-class-dot");
      document.getElementById("icon-setActive-1").classList.remove("d-none");
      document.getElementById("icon-setActive-2").classList.remove("d-none");
      document.getElementById("icon-setActive-3").classList.remove("d-none");
      document.getElementById("icon-setActive-4").classList.remove("d-none");
      document.getElementById("icon-setActive-5").classList.remove("d-none");
      document.getElementById("icon-setActive-6").classList.add("d-none");
      document.getElementById("icon-set-6").classList.remove("d-none");
      setMainImg("/assets/icons/coin_6.png");
    }
  };
  return (
    <>
      <div className="text-white coin-progress-container">
        <div className="progres-left">
          <div className="coin-pack-section">
            <h1 className="banner-title download-play-title font-trajan text-left mb-2">
              coins packs
            </h1>
            <p className="banner-desc text-left mb-5">
              Buy the Coins pack of your choice.
            </p>
          </div>
          <ul className="d-flex justify-content-between line-progress">
            <li className="">
              <div className="text-center">
                <div className="pro-img d-flex align-items-center justify-content-center">
                  <img
                    src="/assets/icons/bronze.png"
                    className="non-active-icon d-none"
                    id="icon-setActive-1"
                    alt="Bronze packge"
                  />
                  <img
                    src="/assets/icons/bronze-selected.png"
                    width="50"
                    className="active-icon"
                    id="icon-set-1"
                    alt="Bronze package Active"
                  />
                </div>
                <p className="progress-text">Bronze</p>
              </div>
              <div
                className={`dot-line text-center mb-3 abc border-class-dot`}
                id="1"
              >
                <GoPrimitiveDot
                  onClick={(e, id) => {
                    handleProgressStep(e, 1);
                  }}
                  size="24"
                />
              </div>
              <div className="text-center progress-text">10 WGC</div>
            </li>
            <li className="">
              <div className="text-center">
                <div className="pro-img d-flex align-items-center justify-content-center">
                  <img
                    src="/assets/icons/silver.png"
                    className="non-active-icon"
                    id="icon-setActive-2"
                    alt="silver package"
                  />
                  <img
                    src="/assets/icons/silver-selected.png"
                    width="50"
                    className="active-icon d-none"
                    id="icon-set-2"
                    alt="silver package"
                  />
                </div>
                <p className="progress-text">Silver</p>
              </div>
              <div className="dot-line text-center mb-3 abc" id="2">
                <GoPrimitiveDot
                  size="24"
                  onClick={(e, id) => {
                    handleProgressStep(e, 2);
                  }}
                />
              </div>
              <div className="text-center progress-text">100 WGC</div>
            </li>
            <li className="">
              <div className="text-center">
                <div className="pro-img d-flex align-items-center justify-content-center">
                  <img
                    src="/assets/icons/gold.png"
                    className="non-active-icon"
                    id="icon-setActive-3"
                    alt="gold package"
                  />
                  <img
                    src="/assets/icons/gold-selected.png"
                    width="50"
                    className="active-icon d-none"
                    id="icon-set-3"
                    alt="gold package"
                  />
                </div>
                <p className="progress-text">Gold</p>
              </div>
              <div className="dot-line text-center mb-3 abc" id="3">
                <GoPrimitiveDot
                  onClick={(e, id) => {
                    handleProgressStep(e, 3);
                  }}
                  size="24"
                />
              </div>
              <div className="text-center progress-text">500 WGC</div>
            </li>
            <li className="">
              <div className="text-center">
                <div className="pro-img d-flex align-items-center justify-content-center">
                  <img
                    src="/assets/icons/platinum.png"
                    className="non-active-icon"
                    id="icon-setActive-4"
                    alt="platinum package"
                  />
                  <img
                    src="/assets/icons/platinum-selected.png"
                    width="50"
                    className="active-icon d-none"
                    id="icon-set-4"
                    alt="platinum package"
                  />
                </div>
                <p className="progress-text">Platinum</p>
              </div>
              <div className="dot-line text-center mb-3 abc" id="4">
                <GoPrimitiveDot
                  onClick={(e, id) => {
                    handleProgressStep(e, 4);
                  }}
                  size="24"
                />
              </div>
              <div className="text-center progress-text">1000 WGC</div>
            </li>
            <li className="">
              <div className="text-center">
                <div className="pro-img d-flex align-items-center justify-content-center">
                  <img
                    src="/assets/icons/diamond.png"
                    className="non-active-icon"
                    id="icon-setActive-5"
                    alt="diamond package"
                  />
                  <img
                    src="/assets/icons/diamond-selected.png"
                    width="50"
                    className="active-icon d-none"
                    id="icon-set-5"
                    alt="diamond package"
                  />
                </div>
                <p className="progress-text">Diamond</p>
              </div>
              <div className="dot-line text-center mb-3 abc" id="5">
                <GoPrimitiveDot
                  onClick={(e, id) => {
                    handleProgressStep(e, 5);
                  }}
                  size="24"
                />
              </div>
              <div className="text-center progress-text">10000 WGC</div>
            </li>
            <li className="">
              <div className="text-center">
                <div className="pro-img d-flex align-items-center justify-content-center">
                  <img
                    src="/assets/icons/rhodium.png"
                    className="non-active-icon"
                    id="icon-setActive-6"
                    alt="rhodium package"
                  />
                  <img
                    src="/assets/icons/rhodium-selected.png"
                    width="50"
                    className="active-icon d-none"
                    id="icon-set-6"
                    alt="rhodium package"
                  />
                </div>
                <p className="progress-text">Rhodium</p>
              </div>
              <div className="dot-line text-center mb-3 abc" id="6">
                <GoPrimitiveDot
                  onClick={(e, id) => {
                    handleProgressStep(e, 6);
                  }}
                  size="24"
                />
              </div>
              <div className="text-center progress-text">60000 WGC</div>
            </li>
          </ul>
          <div className="coin-pack-section buy-coin-hide mt-5">
            <Link className="btn btn-primary-bg btn-primary-cs" to="/buy-credits">
              Buy Credits
              <span className="ms-2">
                <BsArrowRight />
              </span>
            </Link>
          </div>
        </div>
        <div>
          <div className="circle-area">
            <img src="/assets/icons/coin1.png" alt="coins" className="coin-1" />
            <img
              src="/assets/icons/coin2.png"
              alt="silver coins"
              className="coin-2"
            />
            <div className="p-5 d-flex align-items-center justify-content-center h-100">
              <img
                src={mainImg}
                width="80%"
                className="img-fluid"
                alt="package coins"
              />
            </div>
          </div>
          <div className="coin-pack-section d-none-cus mt-5">
            <Link
              to="/buy-credits"
              className="btn btn-primary-bg btn-primary-cs "
            >
              Buy Credits
              <span className="ms-2">
                <BsArrowRight />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoinsProgress;
