import React from "react";

import AboutWgc from "../../components/AboutWgc";
import CoinsProgress from "../../components/CoinsProgress";
import DownloadPlaySection from "../../components/DownloadPlaySection";
import GameSlider from "../../components/GameSlider";
import VideoBanner from "../../components/VideoBanner";
function Home() {
  return (
    <>
      <VideoBanner />
      <DownloadPlaySection />
      <div className="game-slider-wrapper">
        <GameSlider />
      </div>
      <div className="coin-banner" style={{ position: "relative" }}>
        <CoinsProgress />
      </div>
      <div className="about-wgc">
        <AboutWgc />
      </div>
    </>
  );
}

export default Home;
