import React from "react";
import { useDispatch } from "react-redux";
import { toggleModal } from "../../../redux/slices/AuthSlice";

function BuyCoinsCard(Props) {
  const dispatch = useDispatch();

  return (
    <>
      <div className="col-md-6 col-lg-4 col-xl-3 py-4">
        <div className="buy-coin-card">
          <div className="buy-coin-inner-card mb-3">
            <div className="bagde-area">
              <p className="badge-text">
                {Props.badgeValue} <span>WGC</span>
              </p>
            </div>
            <div className="text-center buy-coin-package-img">
              <img
                src={Props.cardImg}
                className="img-fluid"
                alt="wgc-thumbnial"
              ></img>
            </div>
            <div>
              <ul className="list-style-none">
                <li className="package-name">{Props.packageName}</li>
                <li className="package-price">{Props.packagePrice}</li>
              </ul>
            </div>
          </div>
          <div className="text-center">
            {Props.isUserLogin ? (
              Props.children
            ) : (
              <button
                type="button"
                className="btn btn-warning-bg btn-primary-cs w-250 my-auto"
                onClick={() => dispatch(toggleModal(true))}
              >
                <span>Buy Now</span>
                <small className="d-block package-buy-small">
                  Login to Buy
                </small>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default BuyCoinsCard;
