import React from "react";
import { TbWorld } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toggleContactModal } from "../redux/slices/AuthSlice";
const Footer = () => {
  // const renderTooltip = () => (
  //   <Tooltip id="button-tooltip">Simple tooltip</Tooltip>
  // );
  const dispatch = useDispatch();
  return (
    <>
      <div className="container text-center">
        <p className="banner-desc">
          <TbWorld className="me-2 link-color" size="24" />
          English
        </p>
        <div className="text-center mb-4">
          <img
            src="/assets/images/footer-logo.png"
            className="img-fluid"
            width="130px"
            alt="wgc-thumbnial"
          ></img>
        </div>
        <div className="text-center footer-menu-section">
          <ul className="mb-1">
            <li>
              <Link to="/about" onClick={() => window.scrollTo(0, 0)}>
                About
              </Link>
            </li>
            <li>
              <Link to="/career" onClick={() => window.scrollTo(0, 0)}>
                Career
              </Link>
            </li>
            <li>
              <Link
                to="#"
                onClick={() => {
                  // window.scrollTo(0, 0);
                  dispatch(toggleContactModal(true));
                }}
              >
                Contact
              </Link>
            </li>
            <li>
              <Link to="/terms-service" onClick={() => window.scrollTo(0, 0)}>
                Terms of Service
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-copy-write">
          <p className="copy-write">
            ©2022 World Game Community. All Rights Reserved
          </p>
        </div>
      </div>

      {/* <OverlayTrigger
            trigger="click"
            key="left"
            placement="left"
            overlay={
              <Popover id={`popover-positioned-left`}>
                <Popover.Header as="h3">{`Popover left`}</Popover.Header>
                <Popover.Body>
                  <strong>Holy guacamole!</strong> Check this info.
                </Popover.Body>
              </Popover>
            }
          >
            <p className="copy-write">
              ©2022 World Game Community. All Rights Reserved
            </p>
          </OverlayTrigger> */}
    </>
  );
};

export default Footer;
