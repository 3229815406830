import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
function TestimonialSlider() {
  return (
    <>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        <div>
          <img
            src="/assets/images/testimonial-slid-img1.png"
            className="testi-user"
            alt="wgc-thumbnial"
          />
          <div className="myCarousel">
            <h3>Vicky Smith</h3>
            <div className="text-center rating-img">
              <img
                src="/assets/images/raiting-stars.png"
                className="img-fluid"
                alt="wgc-thumbnial"
              ></img>
            </div>
            <p>
              “World Game wide range of must-have products and accesories make
              it a breeze to gain traction in social channels - especially
              during seasonal peaks. They're also an extremely responsive
              partner that truly understands what it takes for an affiliate
              program to thrive”
            </p>
          </div>
        </div>

        <div>
          <img
            src="/assets/images/testimonial-slid-img2.png"
            className="testi-user"
            alt="wgc-thumbnial"
          />

          <div className="myCarousel">
            <h3>Alan the Gamer</h3>
            <div className="text-center rating-img">
              <img
                src="/assets/images/raiting-stars.png"
                className="img-fluid"
                alt="wgc-thumbnial"
              ></img>
            </div>
            <p>
              “I had the funniest time in ages. The games were like fun arcade
              games but very addicting. There were games for both casual and
              hardcore gamers. Being a gamer I feel almost guilty making a lot
              of money while doing what I love doing. Hope you guys continue to
              be successful so I can keep earning an income for years to come!”
            </p>
          </div>
        </div>

        <div>
          <img
            src="/assets/images/testimonial-slid-img3.png"
            className="testi-user"
            alt="wgc-thumbnial"
          />
          <div className="myCarousel">
            <h3>John</h3>
            <div className="text-center rating-img">
              <img
                src="/assets/images/raiting-stars.png"
                className="img-fluid"
                alt="wgc-thumbnial"
              ></img>
            </div>
            <p>
              “This is like the most legit play and win game company ever! they
              have genuine games, they pay very high commission and are
              continually adding new game titles. It's really a fun company to
              be earning for.”
            </p>
          </div>
        </div>
        <div>
          <img
            src="/assets/images/testimonial-slid-img4.png"
            className="testi-user"
            alt="wgc-thumbnial"
          />
          <div className="myCarousel">
            <h3>Ronald</h3>
            <div className="text-center rating-img">
              <img
                src="/assets/images/raiting-stars.png"
                className="img-fluid"
                alt="wgc-thumbnial"
              ></img>
            </div>
            <p>
              “They have the funniest games. My family and friends loved it when
              I introduced this to them. They simply top up, play and cash out!
              We earn from everyone that joins through our link for many years.
              It's changed my life! join quickly and earn like I'm earning.
              Thank you World Game Community, I'm super proud of you.”
            </p>
          </div>
        </div>
        <div>
          <img
            src="/assets/images/testimonial-slid-img5.png"
            className="testi-user"
            alt="wgc-thumbnial"
          />
          <div className="myCarousel">
            <h3>Bob</h3>
            <div className="text-center rating-img">
              <img
                src="/assets/images/raiting-stars.png"
                className="img-fluid"
                alt="wgc-thumbnial"
              ></img>
            </div>
            <p>
              “It's really fun to be able to play games with people all around
              the world. It's like a common language between all races. Here
              people are having fun and is super respectful of each other. A
              very nice culture of gaming and fun.”
            </p>
          </div>
        </div>
      </Carousel>
    </>
  );
}

export default TestimonialSlider;
