import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { BsArrowRight } from "react-icons/bs";
function Fariness() {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <Accordion defaultActiveKey={["0"]}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                How does WGC ensure fairness of each game?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                WGC game platform uses proprietary blockchain which prevents
                tempering and makes coins secure against hackers. Blockchains
                can be traced through 3rd party verification websites, adding
                credibility to the integrity of the system.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                What is skills-based gaming?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                <p>
                  Skill-based gaming has a well-established legal, social and
                  commercial history. From classic board games to major sports
                  tournaments, games of skill have long offered participants a
                  chance to compete based on one's ability. Today, games of
                  skill are available on most major media sites like AOL, MSN,
                  and Yahoo, and are complemented by an emerging electronic
                  sports (eSports) industry that lets professional gamers
                  compete in popular video games with real money at stake.
                </p>
                <p>
                  WGC game platform has advanced this trend, enabling
                  skill-based multiplayer tournaments on mobile devices while
                  offering gamers the ability to compete for real prizes. Games
                  powered by WGC take the clear distinction as being games of
                  skill and not games of chance.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                Are Skill-based games Gambling?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                Skill-based games with currency involved are not considered
                gambling because the generally accepted definition of gambling
                involves three specific things: (1) the award of a prize, (2)
                paid-in consideration (meaning entrants pay to compete) and (3)
                an outcome determined on the basis of chance. Without all three
                of these elements, a competition that reward real prizes is not
                gambling. In the case of our games, outcomes are not determined
                by chance, but are rather achieved through a player's skill or
                ability, making our skills-based games legal.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                How are skill-based games different from chance-based games?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                <p>
                  Games of skill require a physical or mental ability and a
                  learned capacity to carry out a result. These games commonly
                  include the use of strategy, tactic, physical coordination,
                  strength, technical expertise, or knowledge.
                </p>
                <p>
                  Games of chance are games with an outcome strongly influenced
                  by random chance or uncertainty. Common randomization devices
                  include dice, playing cards, or numbered balls drawn from a
                  container.
                </p>
                <p>
                  Games of chance may have some skill, and games of skill may
                  have some chance, however, most U.S. courts use either the
                  predominance test or the material element test to look at the
                  role that skill and chance each take in determining the
                  outcome of the game.
                </p>
                <p>
                  <strong>The Predominance Test</strong>
                </p>
                <p>
                  The predominance test is the most commonly used indicator of
                  whether a game is skill- or chance-based. Under this test, one
                  must envision a continuum with pure skill on one end and pure
                  chance on the other. On the continuum, games such as chess
                  would be almost at the pure skill end, while traditional slot
                  machines would be at the pure chance end. Between these ends
                  of the spectrum lie many activities containing both elements
                  of skill and chance. A game is classified as a game of skill
                  if the game falls predominantly closer to the skill end of the
                  continuum.
                </p>
                <p>
                  <strong>The Material Element Test</strong>
                </p>
                <p>
                  The material element test is the second most commonly used
                  test in the U.S. and is relied upon by 8 states to evaluate
                  whether a game is skill or chance based. The test asks the
                  question of whether chance plays a material role in
                  determining a game's outcome. As an example, in games like
                  Minesweeper, a great deal of skill is generally exercised by
                  players, but there are moments when players are forced to
                  guess at random, with the results of that guess determining
                  the winner and loser of the game. Skill predominates but
                  chance plays the material role in determining the game's
                  outcome.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                What happens if I tie with my opponent in a draw match?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                <p>
                  If it occurs in a Real-time PVP game, entry fee will be
                  returned to both of you, however, you are given the option to
                  re-challenge your opponent if you want to at the end of each
                  game.
                </p>
                <p>
                  If it occurs in a Passive PVP game, you will be asked to
                  replay the same game and the new score will be used to compete
                  against the other player. A final winner will be determined by
                  this new score submitted.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                Notes
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                <strong> NOTE:</strong> All gamers must be at least 18 years old
                and their device location settings must be enabled to ensure
                Skill eligibility.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
}

export default Fariness;
