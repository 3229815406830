import React from "react";
import { BsDot } from "react-icons/bs";

import DownloadGameAccordion from "./components/DownloadGameAccordion";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
// import downloadImg from "/assets/images/download-icon-black.png"
function DownloadGame() {
  const percentage = 75;
  // const downloadImg = (
  //   <>
  //     <img
  //       src="/assets/images/download-icon-black.png"
  //       width="90px"
  //       alt="wgc-thumbnial"
  //     />
  //   </>
  // );
  return (
    <>
      <div className="games-section">
        <div className="container">
          <div className="row">
            <div className="col-12 pb-5">
              <div className="text-center">
                <div className="mx-auto mb-4" style={{ width: "160px" }}>
                  <CircularProgressbarWithChildren
                    value={percentage}
                    strokeWidth="2"
                    background
                    styles={buildStyles({
                      backgroundColor: "#15171e",
                      textColor: "#a3eb00",
                      pathColor: "#a3eb00",
                      trailColor: "#000000",
                      strokeLinecap: "butt",
                    })}
                  >
                    <img
                      style={{ width: 90, marginTop: -5 }}
                      src="/assets/images/download-icon-black.png"
                      alt="Download"
                    />
                    <div
                      className="font-trajan"
                      style={{ fontSize: 20, marginTop: -3, color: "#a3eb00" }}
                    >
                      <strong>{percentage}%</strong>
                    </div>
                  </CircularProgressbarWithChildren>
                </div>

                <h1 className="banner-title mb-5 font-trajan border-center">
                  Downloading
                </h1>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column mb-3">
              <h4 className="font-trajan h4-cs-style steps-border text-start">
                Step 1
              </h4>
              <div className="flex-cs">
                <p className="text-white mb-0 step-text-list step-text-list-download">
                  <span className="dot-icon-sizing">
                    <BsDot size={50} className="dot-color" />
                  </span>
                  <span>
                    Download game From <strong>World Game Community</strong>
                  </span>
                </p>
                <p className="text-white mb-0 step-text-list step-text-list-download">
                  <span className="dot-icon-sizing">
                    <BsDot size={50} className="dot-color" />
                  </span>
                  <span>Install the downloaded APK</span>
                </p>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column mb-3">
              <h4 className="font-trajan h4-cs-style steps-border text-start">
                Step 2
              </h4>
              <div className="flex-cs">
                <p className="text-white mb-0 step-text-list step-text-list-download">
                  <span className="dot-icon-sizing">
                    <BsDot size={50} className="dot-color" />
                  </span>
                  <span>Create Account</span>
                </p>
                <p className="text-white mb-0 step-text-list step-text-list-download">
                  <span className="dot-icon-sizing">
                    <BsDot size={50} className="dot-color" />
                  </span>
                  <span>Log In to account to play games</span>
                </p>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column mb-3">
              <h4 className="font-trajan h4-cs-style steps-border text-start">
                Step 3
              </h4>
              <div className="flex-cs">
                <p className="text-white mb-0 step-text-list step-text-list-download">
                  <span className="dot-icon-sizing">
                    <BsDot size={50} className="dot-color" />
                  </span>
                  <span>Wager Coins</span>
                </p>
                <p className="text-white mb-0 step-text-list step-text-list-download">
                  <span className="dot-icon-sizing">
                    <BsDot size={50} className="dot-color" />
                  </span>
                  <span>Play in Practise mode</span>
                </p>
                <p className="text-white mb-0 step-text-list step-text-list-download">
                  <span className="dot-icon-sizing">
                    <BsDot size={50} className="dot-color" />
                  </span>
                  <span>Play and Earn</span>
                </p>
              </div>
            </div>
          </div>
          <DownloadGameAccordion />
        </div>
      </div>
    </>
  );
}

export default DownloadGame;
