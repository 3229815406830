import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { postRequest } from "../utility/api";
import { toast } from "react-toastify";
import { axiosInstance } from "../utility/interceptor";
import { useDispatch, useSelector } from "react-redux";
// import jwt_decode from "jwt-decode";
import {
  authUserAction,
  logoutAction,
  toggleContactModal,
  toggleModal,
} from "../redux/slices/AuthSlice";
import { ColorRing } from "react-loader-spinner";
import { FaPlus } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";

// error form style

const errorStyle = {
  color: "red",
  fontSize: "14px!important",
};

function Header() {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    authData,
    isLogin: isUserLogin,
    isShowModal,
    isShowContactModal,
  } = useSelector((state) => state.auth);
  const [senderName, setSenderName] = useState("");
  const [senderMessage, setSenderMessage] = useState("");

  // check if url contain register sponsor
  const { search, pathname } = useLocation();

  const registerSponsor = search.split("sponsor=")[1];

  const [show, setShow] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const [haveAccount, setHaveAccount] = useState(true);
  const [isResetpasswordScreen, setIsResetpasswordScreen] = useState(true);
  const [isResetPasswordSend, setIsResetPasswordSend] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [proSetting, setProSetting] = useState(false);
  const [updatePassword, setUpdatePassword] = useState({
    new_password: "",
    confirm_password: "",
    error: {},
  });

  const handleCloseLogin = () => {
    setShow(false);
    setIsLogin(true);
    setHaveAccount(true);
    setIsResetPasswordSend(false);
    setIsResetpasswordScreen(true);
    reset();
  };
  const handleShowLogin = () => {
    if (registerSponsor) {
      setValue("sponsor", registerSponsor);
    }
    setShow(true);
  };
  const handleCloseContactUs = () => setShowContactUs(false);
  const handleShowContactUs = () => setShowContactUs(true);
  const handleRegister = () => {
    setHaveAccount(false);
  };
  const handleloginPage = () => {
    setHaveAccount(true);
  };
  const handleResetPassword = () => {
    setIsResetpasswordScreen(false);
  };

  // registerUserForm
  const registerUserForm = async (data) => {
    let postData = {
      username: data.username,
      email: data.email,
      password: data.password,
      sponsor: data.sponsor,
    };
    setIsLoading(true);
    try {
      let result = await postRequest("/Auth/register", postData);
      if (result) {
        const requestData = new URLSearchParams();
        requestData.append("client_id", 1);
        requestData.append("grant_type", "password");
        requestData.append("password", data.password);
        requestData.append("scope", "api offline_access");
        requestData.append("username", data.username);
        const options = {
          headers: { "content-type": "application/x-www-form-urlencoded" },
        };

        let response = await postRequest("/auth/token", requestData, options);
        if (response.status === 200) {
          toast.success("register successfully");
          localStorage.setItem("sgc-token", response.data.access_token);
          // let decoded = jwt_decode(response.data.id_token);
          localStorage.setItem(
            "exp",
            response.data.expires_in * 1000 + Date.now()
          );
          getUserInfo(response.data.access_token);
        }
      }
    } catch (error) {
      if (error.response.data) {
        toast.error(error.response.data.Message);
      } else {
        toast.error("Bad request try later");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // login form
  const loginForm = async (data) => {
    setIsLoading(true);
    const requestData = new URLSearchParams();
    requestData.append("client_id", 1);
    requestData.append("grant_type", "password");
    requestData.append("password", data.password);
    requestData.append("scope", "api offline_access");
    requestData.append("username", data.username);
    const options = {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    };
    try {
      let response = await postRequest("/auth/token", requestData, options);
      if (response.status === 200) {
        toast.success("login successfully");
        localStorage.setItem("sgc-token", response.data.access_token);
        // let decoded = jwt_decode(response.data.id_token);
        localStorage.setItem(
          "exp",
          response.data.expires_in * 1000 + Date.now()
        );

        getUserInfo(response.data.access_token);
      }
    } catch (error) {
      if (error.response.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Bad request try later");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // resetPasswordSend
  const resetPasswordSend = async (data) => {
    setIsLoading(true);
    try {
      let response = await axiosInstance.post(
        "/user/trigger-reset-password",
        data.email,
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );

      if (response) {
        toast.success("Reset password code send to your email");
        reset();
        setIsResetPasswordSend(true);
      }
    } catch (error) {
      if (error.response.status === 404)
        toast.error("User not found, please enter correct email address");
      if (error.response.data) {
        toast.error(error.response.data.Message);
      } else {
        toast.error("Bad request try later");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // resetPassword
  const resetPassword = async (data) => {
    setIsLoading(true);
    try {
      let response = await axiosInstance.post(
        `/user/reset-password?resetpasscode=${data.code}`,
        data.password,
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );

      if (response) {
        toast.success("Password changed successfully");
        reset();
        setIsResetPasswordSend(false);
        setIsResetpasswordScreen(true);
        setHaveAccount(true);
        setShow(false);
        handleShowLogin();
      }
    } catch (error) {
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Bad request try later");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // update password
  const updatePasswordHandler = async () => {
    if (!updatePassword.new_password) {
      setUpdatePassword({
        ...updatePassword,
        error: { new_password: "Password is required" },
      });
      return;
    }
    if (!updatePassword.confirm_password) {
      setUpdatePassword({
        ...updatePassword,
        error: { confirm_password: "Confirm password is required" },
      });
      return;
    }

    if (updatePassword.new_password !== updatePassword.confirm_password) {
      setUpdatePassword({
        ...updatePassword,
        error: { confirm_password: "Password doesn't match" },
      });
      return;
    }

    setIsLoading(true);
    try {
      let response = await axiosInstance.post(
        `/profile/password`,
        updatePassword.new_password,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("sgc-token")}`,
            "content-type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Password updated successfully");
        setUpdatePassword({
          new_password: "",
          confirm_password: "",
          error: {},
        });
        setProSetting(false);
      }
    } catch (error) {
      toast.error("Bad request try later");
    } finally {
      setIsLoading(false);
    }
  };

  // user basic info
  const getUserInfo = async (token) => {
    axiosInstance
      .get("/profile/basicinfo", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        localStorage.setItem("userdata-sgc", JSON.stringify(res.data));
        dispatch(authUserAction(res.data));
        reset();
        setIsLogin(true);
        setTimeout(() => {
          setShow(false);
          if (pathname === "/" || pathname === "/register") {
            navigate("/profile");
          }
        }, 1000);
      });
  };

  const logout = () => {
    toast.success("User logged out");
    localStorage.clear();
    dispatch(logoutAction());
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  // dispatch show state in redux
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(toggleModal(show));
  }, [show, dispatch]);

  // set setShow state according to  isShowModal
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setShow(isShowModal);
  }, [isShowModal]);

  // dispatch showContactUs state in redux
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(toggleContactModal(showContactUs));
  }, [showContactUs, dispatch]);

  // set setShow state according to  isShowModal
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setShowContactUs(isShowContactModal);
  }, [isShowContactModal]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (registerSponsor) {
      setHaveAccount(false);
      setShow(true);
      setValue("sponsor", registerSponsor);
    }
  }, [registerSponsor, setValue]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    let mainMenu = document.getElementById("main-menu");
    var navLinks = mainMenu.getElementsByClassName("nav-item");
    for (var i = 0; i < navLinks.length - 1; i++) {
      navLinks[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("active-menu");
        current[0].className = current[0].className.replace(" active-menu", "");
        this.className += " active-menu";
      });
    }
  });
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg-dark-menu"
        variant="dark"
      >
        <Container>
          <Navbar.Brand href="/" tag="a">
            <img
              className="main-logo"
              src="/assets/images/logo.png"
              alt="wgc-thumbnial"
            ></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto" id="main-menu">
              <li className="nav-item active-menu">
                <Link className="nav-link" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item ">
                <Link className="nav-link" to="/about">
                  About
                </Link>
              </li>
              <li className="nav-item ">
                <Link className="nav-link" to="/games">
                  Games
                </Link>
              </li>
              <li className="nav-item ">
                <Link className="nav-link" to="/buy-credits">
                  Buy Credits
                </Link>
              </li>
              <li className="nav-item ">
                {isUserLogin ? (
                  <Link className="nav-link" to="/affiliate-dashboard">
                    Affiliate
                  </Link>
                ) : (
                  <Link className="nav-link" to="/affiliate">
                    Affiliate
                  </Link>
                )}
              </li>
              <li className="nav-item ">
                <Link className="nav-link" to="/career">
                  Career
                </Link>
              </li>
              <li className="nav-item ">
                <Link
                  className="nav-link"
                  tag="button"
                  to="#"
                  onClick={handleShowContactUs}
                >
                  Contact
                </Link>
              </li>
            </Nav>
            <Nav>
              {isUserLogin === false ? (
                <>
                  <button
                    className="btn btn-login"
                    onClick={handleShowLogin}
                  >
                    Login
                  </button>
                </>
              ) : (
                <>
                  <div>
                    {/* <Link to="/profile" className="text-white">
                      {authData?.displayname}
                      <span className="ms-2">
                        <img 
                          src={authData?.avatarurl}
                          className="img-fluid img-rounded profile-img"
                          width="40px"
                          height="40px"
                        ></img>
                      </span>
                    </Link> */}
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        {authData?.displayname}
                        <span className="ms-2">
                          <img
                            src={
                              authData?.avatarurl
                                ? authData?.avatarurl
                                : "/assets/images/testimonial-slid-img.png"
                            }
                            className="img-fluid img-rounded profile-img"
                            width="40px"
                            height="40px"
                            alt="wgc-thumbnial"
                          ></img>
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Link
                          to="/profile"
                          className="dropdown-item-cs dropdown-item"
                        >
                          Profile
                        </Link>

                        <Dropdown.Item
                          href="#"
                          role="button"
                          className="dropdown-item-cs"
                          onClick={() => {
                            setProSetting(true);
                          }}
                        >
                          Change Password
                        </Dropdown.Item>

                        <Dropdown.Item
                          href="#"
                          role="button"
                          onClick={logout}
                          className="dropdown-item-cs"
                        >
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  {/* <button
                    className="btn btn-login"
                    style={{
                      marginLeft: "1.5rem",
                      padding: "0.3rem 0.4rem",
                      width: "6rem",
                    }}
                    onClick={logout}
                  >
                    Logout
                  </button> */}
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <>
        <Modal
          show={show}
          onHide={handleCloseLogin}
          centered
          size="md"
          backdrop="static"
          keyboard={false}
          className=""
        >
          <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
          <Modal.Body className="px-5 pb-5">
            <div>
              <h1 className="banner-title login-heading mb-1 font-trajan text-center">
                {isResetpasswordScreen ? (
                  <>{haveAccount ? "LOG IN" : "REGISTRATION"}</>
                ) : (
                  "RESET"
                )}
              </h1>
              <p className="text-center banner-desc text-light">
                {isResetpasswordScreen ? (
                  <>
                    {haveAccount
                      ? "Welcome back!"
                      : "Welcome to the world of gaming credits!"}
                  </>
                ) : (
                  "Password"
                )}
              </p>
            </div>
            <div>
              <form>
                {isResetpasswordScreen ? (
                  <>
                    {haveAccount && (
                      <>
                        <div className="form-group mb-4">
                          <input
                            className="form-control rounded-0 form-control-lg"
                            placeholder="Username"
                            {...register("username", {
                              required: "username is required",
                            })}
                          />
                          {errors?.username?.message && (
                            <div style={errorStyle}>
                              {errors.username.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group mb-4">
                          <input
                            type="password"
                            className="form-control rounded-0 form-control-lg"
                            placeholder="Password"
                            {...register("password", {
                              required: "password is required",
                            })}
                          />
                          {errors?.password?.message && (
                            <div style={errorStyle}>
                              {errors.password.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group mb-4">
                          <button
                            type="submit"
                            className="btn btn-dark rounded-0 w-100 btn-lg"
                            onClick={handleSubmit(loginForm)}
                            disabled={isLoading}
                          >
                            Log in
                            {isLoading && (
                              <ColorRing
                                visible={true}
                                height="30"
                                width="30"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                                colors={[
                                  "white",
                                  "white",
                                  "white",
                                  "white",
                                  "white",
                                ]}
                              />
                            )}
                          </button>
                        </div>
                        <div>
                          <div className="d-flex flex-wrap align-items-center justify-content-between text-light mb-2">
                            <p className="mb-0 login-reset">New to WGC?</p>
                            <span
                              tag="button"
                              className="login-reset text-Link-cs"
                              onClick={handleRegister}
                            >
                              Register
                            </span>
                          </div>
                          <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <p className="mb-0 login-reset">Forgot Password?</p>
                            <span
                              className="login-reset text-Link-cs"
                              onClick={handleResetPassword}
                            >
                              Reset Passsword
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    {haveAccount === false && (
                      <>
                        <div className="form-group mb-4">
                          <input
                            className="form-control rounded-0 form-control-lg"
                            placeholder="Username"
                            {...register("username", {
                              required: "Username is required",
                            })}
                          />
                          {errors?.username?.message && (
                            <div style={errorStyle}>
                              {errors.username.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group mb-4">
                          <input
                            className="form-control rounded-0 form-control-lg"
                            placeholder="Email"
                            {...register("email", {
                              required: "Valid Email address is required",
                            })}
                          />
                          {errors?.email?.message && (
                            <div style={errorStyle}>{errors.email.message}</div>
                          )}
                        </div>
                        <div className="form-group mb-4">
                          <input
                            type="password"
                            className="form-control rounded-0 form-control-lg"
                            placeholder="Password"
                            {...register("password", {
                              required: "You must specify a password",
                              minLength: {
                                value: 8,
                                message:
                                  "Password must have at least 8 characters",
                              },
                            })}
                          />
                          {errors?.password?.message && (
                            <div style={errorStyle}>
                              {errors.password.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group mb-4">
                          <input
                            type="password"
                            className="form-control rounded-0 form-control-lg"
                            placeholder="Confirm Password"
                            {...register("cpassword", {
                              validate: (value) => {
                                const { password } = getValues();
                                return (
                                  password === value ||
                                  "The passwords do not match"
                                );
                              },
                            })}
                          />
                          {errors?.cpassword?.message && (
                            <div style={errorStyle}>
                              {errors.cpassword.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group mb-4">
                          <input
                            className="form-control rounded-0 form-control-lg"
                            placeholder="Sponsor (Optional)"
                            required={false}
                            {...register("sponsor")}
                          />
                        </div>
                        <div className="form-group mb-4">
                          <button
                            type="submit"
                            className="btn btn-dark rounded-0 w-100 btn-lg"
                            onClick={handleSubmit(registerUserForm)}
                            disabled={isLoading}
                          >
                            Register
                            {isLoading && (
                              <ColorRing
                                visible={true}
                                height="30"
                                width="30"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                                colors={[
                                  "white",
                                  "white",
                                  "white",
                                  "white",
                                  "white",
                                ]}
                              />
                            )}
                          </button>
                        </div>
                        <div>
                          <div className="d-flex flex-wrap align-items-center justify-content-between mb-2">
                            <p className="mb-0 login-reset">
                              Already have a Account?
                            </p>
                            <span
                              tag="button"
                              className="login-reset text-Link-cs"
                              onClick={handleloginPage}
                            >
                              Login
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : isResetPasswordSend ? (
                  <>
                    <div className="form-group mb-4">
                      <input
                        className="form-control rounded-0 form-control-lg"
                        placeholder="Code"
                        {...register("code", {
                          required: "Code is required",
                        })}
                      />
                      {errors?.code?.message && (
                        <div style={errorStyle}>{errors.code.message}</div>
                      )}
                    </div>
                    <div className="form-group mb-4">
                      <input
                        type="password"
                        className="form-control rounded-0 form-control-lg"
                        placeholder="Password"
                        {...register("password", {
                          required: "You must specify a password",
                          minLength: {
                            value: 8,
                            message: "Password must have at least 8 characters",
                          },
                        })}
                      />
                      {errors?.password?.message && (
                        <div style={errorStyle}>{errors.password.message}</div>
                      )}
                    </div>
                    <div className="form-group mb-4">
                      <input
                        type="password"
                        className="form-control rounded-0 form-control-lg"
                        placeholder="Confirm Password"
                        {...register("cpassword", {
                          validate: (value) => {
                            const { password } = getValues();
                            return (
                              password === value || "The passwords do not match"
                            );
                          },
                        })}
                      />
                      {errors?.cpassword?.message && (
                        <div style={errorStyle}>{errors.cpassword.message}</div>
                      )}
                    </div>
                    <div className="form-group mb-4">
                      <button
                        type="submit"
                        className="btn btn-dark rounded-0 w-100 btn-lg"
                        onClick={handleSubmit(resetPassword)}
                        disabled={isLoading}
                      >
                        Reset Password
                        {isLoading && (
                          <ColorRing
                            visible={true}
                            height="30"
                            width="30"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={[
                              "white",
                              "white",
                              "white",
                              "white",
                              "white",
                            ]}
                          />
                        )}
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="form-group mb-4">
                      <input
                        className="form-control rounded-0 form-control-lg"
                        placeholder="Email"
                        {...register("email", {
                          required: "Valid Email address is required",
                        })}
                      />
                      {errors?.email?.message && (
                        <div style={errorStyle}>{errors.email.message}</div>
                      )}
                    </div>

                    <div className="form-group mb-4">
                      <button
                        type="submit"
                        className="btn btn-dark rounded-0 w-100 btn-lg"
                        onClick={handleSubmit(resetPasswordSend)}
                        disabled={isLoading}
                      >
                        Reset Password
                        {isLoading && (
                          <ColorRing
                            visible={true}
                            height="30"
                            width="30"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={[
                              "white",
                              "white",
                              "white",
                              "white",
                              "white",
                            ]}
                          />
                        )}
                      </button>
                    </div>
                    <div>
                      <div className="text-center px-4">
                        <p className="mb-0 login-reset text-center">
                          You will get the reset Password link at your email ID
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </form>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={showContactUs}
          onHide={handleCloseContactUs}
          centered
          size="md"
          backdrop="static"
          keyboard={false}
          className=""
        >
          <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
          <Modal.Body className="px-5 pb-5">
            <div>
              <h1 className="banner-title login-heading mb-1 font-trajan text-center">
                CONTACT US
              </h1>
              <p className="text-center banner-desc text-light">
                We are happy to help you!<br></br>
                Please share your concerns
              </p>
            </div>
            <div>
              <form>
                <div className="form-group mb-4">
                  <input
                    className="form-control rounded-0 form-control-lg"
                    placeholder="Name"
                    value={senderName}
                    onChange={(e) => setSenderName(e.target.value)}
                  />
                </div>
                <div className="form-group mb-4">
                  <input
                    className="form-control rounded-0 form-control-lg"
                    placeholder="Email"
                  />
                </div>
                <div className="form-group mb-4">
                  <textarea
                    className="form-control rounded-0 form-control-lg"
                    placeholder="Message"
                    rows="4"
                    value={senderMessage}
                    onChange={(e) => setSenderMessage(e.target.value)}
                  />
                </div>
                <div className="form-group mb-4">
                  <a
                    href={`mailto:info@worldgamecommunity.com?subject=${senderName}&body=${senderMessage}`}
                    className="btn btn-dark rounded-0 w-100 btn-lg"
                  >
                    Send
                  </a>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>

        {/* update password modal  */}
        <Modal
          show={proSetting}
          onHide={() => setProSetting(false)}
          centered
          size="md"
          backdrop="static"
          keyboard={false}
          className=""
        >
          <form>
            <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
            <Modal.Body className="px-5 pb-5">
              <div>
                <h1 className="banner-title login-heading mb-4 font-trajan text-center">
                  Update Password
                </h1>

                <div className="form-group mb-4">
                  <input
                    type="password"
                    className="form-control rounded-0 form-control-lg"
                    placeholder="New Password"
                    onChange={(e) => {
                      setUpdatePassword({
                        ...updatePassword,
                        error: {},
                        new_password: e.target.value,
                      });
                    }}
                  />
                  {updatePassword.error?.new_password && (
                    <div style={errorStyle}>
                      {updatePassword.error?.new_password}
                    </div>
                  )}
                </div>
                <div className="form-group mb-4">
                  <input
                    type="password"
                    className="form-control rounded-0 form-control-lg"
                    placeholder="Confirm Password"
                    onChange={(e) => {
                      setUpdatePassword({
                        ...updatePassword,
                        error: {},
                        confirm_password: e.target.value,
                      });
                    }}
                  />
                  {updatePassword.error?.confirm_password && (
                    <div style={errorStyle}>
                      {updatePassword.error?.confirm_password}
                    </div>
                  )}
                </div>
                <div className="form-group mb-4">
                  <button
                    type="button"
                    className="btn btn-dark rounded-0 w-100 btn-lg"
                    onClick={updatePasswordHandler}
                    disabled={isLoading}
                  >
                    Update Password
                    {isLoading && (
                      <ColorRing
                        visible={true}
                        height="30"
                        width="30"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={["white", "white", "white", "white", "white"]}
                      />
                    )}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </form>
        </Modal>
      </>
      {/* floating button */}
      <div className="floating-button">
        <div className="mainopShadow"></div>
        <div className="fab">
          <div className="mainop">
            <i id="addIcon" className="material-icons">
              <FaPlus />
            </i>
          </div>
          <div id="sheets" className="minifab op2" onClick={handleShowLogin}>
            <img
              className="minifabIcon"
              src="/assets/images/join-now-float-btn.png"
              alt="wgc-thumbnial"
            />
          </div>
          <div className="top-tip-join-now py-2">
            <h6 className="text-white text-uppercase mb-0">Join Now</h6>
          </div>
          <a
            href="https://discord.gg/esHdyeBCVY"
            target="_blank"
            id="docs"
            className="minifab op1"
            rel="noreferrer"
          >
            <img
              className="minifabIcon"
              src="/assets/images/discurd-float-btn.png"
              alt="wgc-thumbnial"
            />
          </a>
          <div className="top-tip-discord py-2">
            <h6 className="text-white text-uppercase mb-0">Discord</h6>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
