// sort date by latest

import moment from "moment";

export const sortDateByLatest = (date) => {
  return date.sort((a, b) => {
    let date1 = new Date(a.date);
    let date2 = new Date(b.date);
    return date2 - date1;
  });
};
export const sortDateByOld = (date) => {
  return date.sort((a, b) => {
    let date1 = new Date(a.date);
    let date2 = new Date(b.date);
    return date1 - date2;
  });
};

export const getSaleReportDate = (salesReport) => {
  let saleGraphDate = [];
  let sortedDate = sortDateByLatest(salesReport);
  let monthInEng = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let salesReportDate = sortedDate.map((sale) => {
    let date = moment(sale.date).utc().format("YYYY-MM-DD");
    let month = date.split("-")[1];
    let day = date.split("-")[2];
    return day + " " + monthInEng[Number(month) - 1];
  });

  salesReport.forEach((item, i) => {
    if (i < 10) {
      saleGraphDate.push(salesReportDate[i]);
    }
  });

  let sortSaleGraphDate = saleGraphDate.reverse();
  return sortSaleGraphDate;
};

export const getSaleReportDateByMonth = (salesReport) => {
  let saleGraphDate = [];
  let sortedDate = sortDateByLatest(salesReport);
  let monthInEng = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let salesReportDate = sortedDate.map((sale) => {
    let date = moment(sale.date).utc().format("YYYY-MM-DD");
    let month = date.split("-")[1];
    return monthInEng[Number(month) - 1];
  });

  salesReport.forEach((item, i) => {
    if (i < 6) {
      saleGraphDate.push(salesReportDate[i]);
    }
  });

  let sortSaleGraphDate = saleGraphDate.reverse();
  return sortSaleGraphDate;
};
