import React from "react";

import { Link } from "react-router-dom";

function AboutWgc() {
  return (
    <>
      <img
        src="/assets/images/fireflam.gif"
        className="img-fluid fire-flame"
        alt="fire flame animation"
      ></img>
      <div className="container py-10">
        <div className="row align-items-center">
          <div className="col-lg-6 pe-3">
            <img
              src="/assets/images/about-img.png"
              className="img-fluid"
              alt="wgc-thumbnial"
            ></img>
          </div>
          <div className="col-lg-6 ps-3 about-right-area">
            <h1 className="banner-title download-play-title font-trajan text-left mb-4">
              About wgc
            </h1>
            <p className="banner-desc text-left mb-4">
              We are a team of serial founders, gamers, and crypto holders who
              believe in the benefits of gaming as a viable way of anyone to
              access transferable skills,social connection, and the
              global,digital economy.
            </p>
            <p className="banner-desc text-left mb-4">
              World Game Community was created becasue we want to make it as seamless
              as possible people who loving gaming to form as communities,
              discover quality games, and gain value for their screen time.
            </p>
            <div className="text-left">
              <Link className="btn btn-warning-bg btn-primary-cs me-4 " to="/about">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutWgc;
