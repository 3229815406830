import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { BsArrowRight } from "react-icons/bs";
function WorldGameCouncilTabContant() {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="banner-title mb-1 font-trajan">
            World Game Community <br></br>Council
          </h1>
          <h4 className="mb-5 font-trajan text-white">WGC DAO</h4>
        </div>
        <div className="col-12">
          <Accordion defaultActiveKey={["0"]}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                Philosophy
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                Our philosophy at World Game Community is deeply rooted in the
                unifying and enriching power of games. We, as a community,
                believe in the transformative potential of gaming, viewing it
                not just as a source of entertainment but as a powerful force
                for good. As we create this shared space, we see games as a
                pathway to understanding, breaking down cultural, linguistic,
                and geographical barriers that often divide us. With over 3.1
                billion gamers worldwide, our philosophy underscores gaming as a
                dynamic medium that fosters common interests, passions, and a
                shared humanity. Games, in our belief, are catalysts that unlock
                creative potentials, develop strategic thinking, and instill
                problem-solving skills, contributing significantly to personal
                growth and global unity.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                Vision
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                The vision we hold for World Game Community extends beyond our
                immediate horizon to encompass a world where games and gamers
                are a positive, unifying force in society. We envision a global
                industry thriving with creativity, fueled by the talents of
                developers, the skills of esports athletes, and the enthusiasm
                of casual gamers. In this vision, generations grow up playing
                games without prejudice or barriers, inspiring the best in
                humanity and contributing to positive global change through the
                remarkable medium of gaming.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                Mission
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                Our mission is at the heart of World Game Community, driving our
                commitment to unite and represent the diverse global gaming
                community. We, as a collective, have created a dynamic platform
                that facilitates the free exchange of ideas, experiences, and
                creativity among gamers, developers, and publishers worldwide.
                Building on our shared interests and passions, our mission is to
                cultivate a common understanding of global issues and
                opportunities within the gaming industry. World Game Community
                functions as a vital bridge between gaming cultures and
                generations, tirelessly working towards a future where
                connectivity and collaboration redefine the gaming landscape
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                Mandate
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                Acting on behalf of millions of gamers worldwide, our mandate is
                clear: advocate for sensible industry regulations that foster
                innovation and growth. A pivotal element of our mandate is the
                continuous fight against biases and misconceptions that obstruct
                the recognition of the societal benefits of gaming. Striving to
                ensure the gamer's voice is not only acknowledged but amplified,
                we proudly carry the mandate to represent the diverse interests
                of the global gaming community.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                Governance
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                Our governance model is built on democratic principles, guided
                by an elected council of industry and community representatives.
                This council, chosen by and from our community, ensures
                accountability in shaping strategies that reflect the evolving
                needs and aspirations of gamers globally. In our governance,
                decisions are made with reason and fairness, with a strong
                emphasis on consensus. Valuing diversity of perspectives and
                promoting inclusiveness in leadership are foundational
                principles that guide our transparent, responsive, and
                representative governance structure
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                Voting
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                At World Game Community, we believe in the power of collective
                decision-making. Open online votes on key strategic issues and
                initiatives are integral to our commitment to democratic and
                inclusive principles. This ensures that the global community of
                gamers actively participates in shaping the future of gaming.
                The outcomes of these votes directly guide our actions,
                demonstrating our unwavering dedication to empowering gamers
                globally and reinforcing their significant influence in
                decisions that impact the community
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
}

export default WorldGameCouncilTabContant;
