import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Account from "./Account";
import Fariness from "./Fariness";
import GamesAndMatching from "./GamesAndMatching";
import General from "./General";
import RewardProgram from "./RewardProgram";
import Support from "./Support";
import WGC from "./WGC";

function QATabs() {
  return (
    <>
      <div className="">
        <Tabs
          defaultActiveKey="account"
          // id="justify-tab-example"
          className="tabs-margin-cs container"
        >
          <Tab
            eventKey="account"
            title="Account"
            className="tabs-section container"
          >
            <Account />
          </Tab>
          <Tab
            eventKey="general"
            title="General"
            className="tabs-section container"
          >
            <General />
          </Tab>
          <Tab
            eventKey="rewardProgram"
            title="RP"
            className="tabs-section container"
          >
            <RewardProgram />
          </Tab>
          <Tab
            eventKey="gamesAndMatching"
            title="Games and Matching"
            className="tabs-section container"
          >
            <GamesAndMatching />
          </Tab>
          <Tab
            eventKey="WGC"
            title="WGC Withdraws & Purchases"
            className="tabs-section container"
          >
            <WGC />
          </Tab>
          <Tab
            eventKey="fariness"
            title="Fariness"
            className="tabs-section container"
          >
            <Fariness />
          </Tab>
          <Tab
            eventKey="support"
            title="Support"
            className="tabs-section container"
          >
            <Support />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default QATabs;
